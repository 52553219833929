import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { useShoppingCart } from "use-shopping-cart";
import { connect } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import {
  city,
  countries,
  fedex,
  orderNow, orderUpdate, productPriceCheck,
  resetOrder, resetPaymentCard,
  states,
  websitepaymentmethods, websiteshippingmethods,
} from "../../actions/commonAction";
import { orderConstants } from "../../constants";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Radio } from "@mui/material";
import SquarePaymentForm from "./SquarePaymentForm";
import "../CheakoutPage/checkoutCard.css";
import QuickBookPaymentForm from "./QuickBookPaymentForm";
import { Accordion } from "react-bootstrap";
import Loader from '../loader';
// import StripePaymentForm from "./StripePaymentForm";

const CheakoutPage = (props) => {
  const { cartDetails, clearCart, removeItem, cartCount, totalPrice } = useShoppingCart();
  const { cart = true, className, reportHandler, productDetail } = props;
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [productWeight, setProductWeight] = useState(0);
  const [fedexSelected, setFedexSelected] = useState("");
  const [fedexAmount, setFedexAmount] = useState(0);
  const [isOrderDisabled, setIsOrderDisabled] = useState(true);
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("boa");
  const [paymentMethodData, setPaymentMethodData] = useState("");
  const [cardPaymentResponse, setCardPaymentResponse] = useState("");
  const [cardPaymentStatus, setCardPaymentStatus] = useState("");
  const [websiteProducts, setWebsiteProducts] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxPer, setTaxPer] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [initialCartCheck, setInitialCartCheck] = useState(true);
  const [isShippingManual, setIsShippingManual] = useState(false);
  const [shippingCountryName, setShippingCountryName] = useState("United States");
  const [shippingStateName, setShippingStateName] = useState("");
  const [shippingCityName, setShippingCityName] = useState("");
  const [billingCountryName, setBillingCountryName] = useState("");
  const [billingStateName, setBillingStateName] = useState("");
  const [billingCityName, setBillingCityName] = useState("");
  const [isBillingManual, setIsBillingManual] = useState(false);
  const [iframeURL, setIframeURL] = useState("");
  const [showingIframe, setShowingIframe] = useState(false);
  const [isLoadingIFrame, setIsLoadingIFrame] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState("");

  const navigate = useNavigate();
  const location = useLocation();



  //price check interval function call server api price checker every 3 seconds
  // useEffect(() => {
  //   const fetchData = () => {
  //     const extractedData = {
  //       website_products: websiteProducts.map(product => ({
  //         sell_price: product.sell_price,
  //         website_product_id: product.website_product_id
  //       }))
  //     };
  //     props.productPriceCheck(extractedData);
  //   };
  //   // Setting up the interval
  //   const intervalId = setInterval(fetchData, 3000);
  //   // Cleanup function to clear the interval when the component is unmounted
  //   return () => clearInterval(intervalId);
  // }, [websiteProducts, props]); // Adding props to dependency array to ensure proper handling


  // useEffect(() => {
  //   if (props.order_price_check_res && props.order_price_check_res.status === 200) {
  //     const data = props.order_price_check_res.data?.data;
  //     if (data) {
  //       data.forEach(product => {
  //           if (product.website_product_id !== undefined) {
  //             removeItem(product.website_product_id);
  //           }
  //       });
  //       setIsModalOpen(true);
  //     }
  //   }
  // }, [props.order_price_check_res]);
  //
  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   navigate("/cart");
  // };

  const [formData, setFormData] = useState({
    customer_shipping_firstname: "",
    customer_shipping_lastname: "",
    customer_shipping_email: "",
    customer_shipping_mobile: "",
    customer_shipping_address1: "",
    customer_shipping_address2: "",
    customer_shipping_state: "US",
    customer_shipping_city: "",
    customer_shipping_postcode: "",
    customer_shipping_country: "US",
    customer_shipping_company: "",
    customer_billing_firstname: "",
    customer_billing_lastname: "",
    customer_billing_email: "",
    customer_billing_mobile: "",
    customer_billing_address1: "",
    customer_billing_address2: "",
    customer_billing_state: "",
    customer_billing_city: "",
    customer_billing_postcode: "",
    customer_billing_country: "",
    customer_billing_company: "",
    is_guest: false, // Set the initial value to false
  });

  const [formErrorData, setFormErrorData] = useState({
    document_id_error: "",
    customer_shipping_firstname_error: "",
    customer_shipping_lastname_error: "",
    customer_shipping_mobile_error: "",
    customer_shipping_email_error: "",
    customer_shipping_address1_error: "",
    customer_shipping_address2_error: "",
    customer_shipping_state_error: "",
    customer_shipping_city_error: "",
    customer_shipping_postcode_error: "",
    customer_shipping_country_error: "",
    customer_shipping_company_error: "",
    customer_billing_firstname_error: "",
    customer_billing_lastname_error: "",
    customer_billing_email_error: "",
    customer_billing_mobile_error: "",
    customer_billing_address1_error: "",
    customer_billing_address2_error: "",
    customer_billing_state_error: "",
    customer_billing_city_error: "",
    customer_billing_postcode_error: "",
    customer_billing_country_error: "",
    customer_billing_company_error: "",
    fedex_error: "",
    is_guest_error: false, // Set the initial value to false
  });

  const resetForm = () => {
    setFormData({
      customer_shipping_firstname: "",
      customer_shipping_lastname: "",
      customer_shipping_mobile: "",
      customer_shipping_email: "",
      customer_shipping_address1: "",
      customer_shipping_address2: "",
      customer_shipping_state: "",
      customer_shipping_city: "",
      customer_shipping_postcode: "",
      customer_shipping_country: "",
      customer_shipping_company: "",
      customer_billing_firstname_error: "",
      customer_billing_lastname_error: "",
      customer_billing_email_error: "",
      customer_billing_mobile_error: "",
      customer_billing_address1: "",
      customer_billing_address2: "",
      customer_billing_state: "",
      customer_billing_city: "",
      customer_billing_postcode: "",
      customer_billing_country: "",
      customer_billing_company: "",
      is_guest: false,
    });
  };

  const handleChangeFedex = (value, amount) => {
    // setPaymentMethod(value);
    amount = Number(amount);
    // if(formData.customer_shipping_state === 'CA'){
    //   let total = totalPrice + amount;
    //   const totalWithTax = (total * 10.25) / 100;
    //   setTaxPer(10.25)
    //   setTaxAmount(totalWithTax)
    // }else {
    //   setTaxPer(0)
    //   setTaxAmount(0)
    // }

    setFedexAmount(amount);
    setFedexSelected(value);

    //fedex error message empty
    setFormErrorData((prevData) => ({
      ...prevData,
      fedex_error: "",
    }));
  };

  const handlePaymentTypeChange = (value, data) => {
    setPaymentMethod(value);
    setPaymentMethodData(data);
  };


  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    // Handle specific field changes (e.g., city)
    if (name === "customer_shipping_city") {
      setShippingCityName(newValue);
    } else if (name === "customer_billing_city") {
      setBillingCityName(newValue);
    }

    // Update the form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    // Clear the error for this field if it's now filled
    if (newValue.trim() !== "") {
      setFormErrorData((prevData) => ({
        ...prevData,
        [`${name}_error`]: "",
      }));
    }
  };

  const dropDownhandleChange = (e) => {
    const { name, type, checked, value, options } = e.target;

    // Get the attribute "dropDownName" from the selected option
    const dropDownName = options[e.target.selectedIndex]?.getAttribute("dropDownName");

    if (name === "customer_shipping_country") {
      setShippingCountryName(dropDownName)
    } else if (name === "customer_shipping_city") {
      setShippingCityName(dropDownName)
    } else if (name === "customer_shipping_state") {
      setShippingStateName(dropDownName)
    } else if (name === "customer_billing_country") {
      setBillingCountryName(dropDownName)
    } else if (name === "customer_billing_state") {
      setBillingStateName(dropDownName)
    } else if (name === "customer_billing_city") {
      setBillingCityName(dropDownName)
    }
    // If the type is checkbox, set the value to the boolean checked state
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    // Clear the error for this field if it's now filled
    if (newValue.trim() !== "") {
      setFormErrorData((prevData) => ({
        ...prevData,
        [`${name}_error`]: "",
      }));
    }
  };

  function clientSideValidateForm(formData, setFormErrorData, paymentMethodShow, shippingStateName,fedexSelected) {
    if (!paymentMethodShow) {
      const fields = [
        { field: 'customer_shipping_firstname', error: 'The website customer shipping firstname field is required' },
        { field: 'customer_shipping_lastname', error: 'The website customer shipping lastname field is required' },
        { field: 'customer_shipping_mobile', error: 'The website customer shipping mobile field is required' },
        { field: 'customer_shipping_email', error: 'The website customer shipping email field is required' },
        // { field: 'customer_shipping_address1', error: 'Address is required' },
        // { field: 'customer_shipping_address2', error: 'Address is required' },
        { field: 'customer_shipping_city', error: 'The website customer shipping city field is required' },
        { field: 'customer_shipping_postcode', error: 'The website customer shipping postcode field is required' },
        { field: 'customer_shipping_company', error: 'The website customer shipping company field is required' },
        { field: 'customer_shipping_country', error: 'The website customer shipping country field is required' }
      ];

      // Handle all other fields
      fields.forEach(({ field, error }) => {
        if (formData[field] === "") {
          setFormErrorData((prevData) => ({
            ...prevData,
            [`${field}_error`]: error,
          }));
        }
      });

      // Handle the customer_shipping_state field separately
      if (shippingStateName.trim() === "") {
        setFormErrorData((prevData) => ({
          ...prevData,
          customer_shipping_state_error: 'The website customer shipping state field is required',
        }));
      }
      if (fedexSelected.trim() === "") {
        setFormErrorData((prevData) => ({
          ...prevData,
          fedex_error: 'Shipping method is required',
        }));
      }
    }
  }

  const handleSubmit = () => {
    clientSideValidateForm(formData, setFormErrorData, paymentMethodShow,shippingStateName,fedexSelected);

    // e.preventDefault(); // This line prevents the default form submission behavior (page refresh)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
    let products = websiteProducts;
    const payment_response = JSON.stringify(cardPaymentResponse);
    const payment_status = JSON.stringify(cardPaymentStatus);

    var net_amount = (totalPrice - discountAmount) + fedexAmount + taxAmount;
    const user_data = JSON.parse(localStorage.getItem('user_data'));

    const data = {
      website_customer_firstname: formData.customer_shipping_firstname,
      website_customer_lastname: formData.customer_shipping_lastname,
      website_customer_mobile: formData.customer_shipping_mobile,
      website_customer_email: formData.customer_shipping_email,
      bill_to_different_address: showBillingDetails ? 1 : 0,
      website_customer_billing_firstname: formData.customer_billing_firstname,
      website_customer_billing_lastname: formData.customer_billing_lastname,
      website_customer_billing_email: formData.customer_shipping_email,
      website_customer_billing_mobile: formData.customer_billing_mobile,
      website_customer_billing_company: formData.customer_billing_company,
      website_customer_billing_address1: formData.customer_billing_address1,
      website_customer_billing_address2: formData.customer_billing_address2,
      website_customer_billing_city: billingCityName,
      website_customer_billing_country: billingCountryName,
      website_customer_billing_postcode: formData.customer_billing_postcode,
      website_customer_billing_state: billingStateName,
      website_customer_shipping_email: formData.customer_shipping_email,
      website_customer_shipping_mobile: formData.customer_shipping_mobile,
      website_customer_shipping_firstname: formData.customer_shipping_firstname,
      website_customer_shipping_lastname: formData.customer_shipping_lastname,
      website_customer_shipping_address1: formData.customer_shipping_address1,
      website_customer_shipping_address2: formData.customer_shipping_address2,
      website_customer_shipping_city: shippingCityName,
      website_customer_shipping_country: shippingCountryName,
      website_customer_shipping_postcode: formData.customer_shipping_postcode,
      website_customer_shipping_state: shippingStateName,
      website_customer_shipping_company: formData.customer_shipping_company,
      is_guest: formData.is_guest,
      shipping_amount: fedexAmount,
      gross_amount: totalPrice,
      net_amount: parseFloat(net_amount).toFixed(2),
      paid_amount: paymentMethod === "quickbook" ? parseFloat(net_amount).toFixed(2) : 0,
      discount: 0,
      discount_amount: discountAmount,
      tax: taxPer,
      tax_amount: parseFloat(taxAmount).toFixed(2),
      shipping_method: fedexSelected,
      payment_method: paymentMethod,
      currency: "USD",
      date: formattedDate,
      type: "Sale",
      website_customer_id: user_data?.user.id ? user_data?.user?.id : 0,
      website_order_products: products,
      payment_status: payment_status,
      payment_response: payment_response,
    };


    setTimeout(() => {
      if (paymentMethod === "square" || paymentMethod === "nmi" || paymentMethod === "boa"  /* && cardPaymentStatus !== undefined && cardPaymentStatus !== ""  && cardPaymentStatus === true */) {
        setIsOrderDisabled(true);
        setInitialCartCheck(false);
        props.orderNow(data);
      } if (paymentMethod === "quickbook" && cardPaymentStatus !== undefined && cardPaymentStatus !== "" && cardPaymentStatus === true) {
        setIsOrderDisabled(true);
        setInitialCartCheck(false);
        props.orderNow(data);
      } if (paymentMethod === "stripe" && cardPaymentStatus !== "") {
        // setInitialCartCheck(false);
        setIsOrderDisabled(true);
        props.orderNow(data);
      } else if (paymentMethod === "bank_transfer") {
        setIsOrderDisabled(true);
        setInitialCartCheck(false);
        if (props.order_res?.status === 201) {
          const data = {
            payment_method : paymentMethod
          }
          props.orderUpdate(props.order_res?.order?.data?.data?.id,data)
        }else{
          props.orderNow(data);
        }

      }
    }, 300);
    // Add your logic for submitting the form data to the server
  };

  useEffect(() => {
    if(props.order_update_res?.status === 200){
      const serializedData = JSON.stringify(props.order_update_res?.order?.data?.data);
      toast.success(`Order Updated Successfully!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      props.resetOrder();
      resetForm();
      clearCart();
      navigate("/thank-you", { state: { orderData: serializedData } });
    }
  },[props.order_update_res]);

  // function updateOrderDetails(status, data){
  //   try {
  //     const serializedData = JSON.stringify(data);
  //     toast.success(`Order Created Successfully!`, {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     props.resetOrder();
  //     resetForm();
  //     clearCart();
  //     navigate("/thank-you", { state: { orderData: serializedData } });
  //
  //   } catch (error) {
  //     console.error("Order creation failed:", error);
  //   }
  //
  // }

  // useEffect(()=>{
  //   // const detailsArray = Object.values(cartDetails);
  //   // let productLenthCount = 0;  // Initialize to 0
  //   // detailsArray.map((item) => {
  //   //   productLenthCount += 1;  // Increment the count
  //   // });
  //   console.log(totalPrice,'out')
  //   setTimeout(() => {
  //     if (totalPrice  < 1 && initialCartCheck) {
  //       console.log(totalPrice,'in')
        // toast.error(`Your cart is empty`, {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        // });
        // setTimeout(() => {
        //   navigate('/cart')
        // }, 2000);
  //     }
  //   }, 7000);
  //
  // }, [totalPrice])


  const [isCartLoading, setIsCartLoading] = useState(true);
  useEffect(() => {
    // Simulate async loading or wait until cart data is available
    const timeout = setTimeout(() => {
      setIsCartLoading(false);
    }, 1000); // Adjust this time if necessary

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!isCartLoading) {
      if (cartCount === 0 && initialCartCheck) {
        toast.error(`Your cart is empty`, {
          position: "top-right",
          autoClose: 3000, // Closes after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setTimeout(() => {
          navigate('/cart'); // Navigate to cart page after 2 seconds
        }, 2000); // Delay of 2 seconds before navigating
      }
    }
  }, [cartCount, isCartLoading, navigate,initialCartCheck]);



  useEffect(() => {
    if (cardPaymentStatus !== "") {
      handleSubmit();
    }
  }, [cardPaymentStatus]);

  useEffect(() => {
    if (showingIframe) {
      setShowingIframe(false);
      props.resetOrder();
    }
  }, [formData, showBillingDetails, fedexSelected]);

  //when product price not match to server price then remove product from cart
  // const handleRemoveItems = (errors, cartDetails, removeItem) => {
  //   if (!errors) return;
  //
  //   // Extract indices from error keys
  //   const indicesToRemove = Object.keys(errors)
  //       .filter(key => key.includes('website_order_products'))
  //       .map(key => parseInt(key.split('.')[1]));
  //
  //   // Get product IDs to remove based on indices
  //   const cartItemsArray = Object.values(cartDetails);
  //   const productIdsToRemove = indicesToRemove
  //       .map(index => cartItemsArray[index]?.id)
  //       .filter(id => id !== undefined);
  //
  //   // Call removeItem for each product ID
  //   productIdsToRemove.forEach(product_id => removeItem(product_id));
  // };


  useEffect(() => {

    if (props.order_res?.message !== "" && props.order_res?.message !== undefined) {
      toast.error(props.order_res?.message, {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsOrderDisabled(false);
      // handleRemoveItems(props.order_res?.errors, cartDetails, removeItem);
      setFormErrorData((prevData) => ({
        ...prevData,
        customer_shipping_firstname_error: props.order_res?.errors?.website_customer_shipping_firstname
            ? props.order_res.errors.website_customer_shipping_firstname[0]
            : "",
        customer_shipping_lastname_error: props.order_res?.errors?.website_customer_shipping_lastname
            ? props.order_res.errors.website_customer_shipping_lastname[0]
            : "",
        customer_shipping_mobile_error: props.order_res?.errors?.website_customer_shipping_mobile
            ? props.order_res.errors.website_customer_shipping_mobile[0] ?? ""
            : "",
        customer_shipping_email_error: props.order_res?.errors?.website_customer_shipping_email
            ? props.order_res.errors.website_customer_shipping_email[0] ?? ""
            : "",
        customer_shipping_address1_error: props.order_res?.errors?.website_customer_shipping_address1
            ? props.order_res.errors.website_customer_shipping_address1[0] ?? ""
            : "",
        customer_shipping_address2_error: props.order_res?.errors?.website_customer_shipping_address2
            ? props.order_res.errors.website_customer_shipping_address2[0] ?? ""
            : "",
        customer_shipping_state_error: props.order_res?.errors?.website_customer_shipping_state
            ? props.order_res.errors.website_customer_shipping_state[0] ?? ""
            : "",
        customer_shipping_city_error: props.order_res?.errors?.website_customer_shipping_city
            ? props.order_res.errors.website_customer_shipping_city[0] ?? ""
            : "",
        customer_shipping_postcode_error: props.order_res?.errors?.website_customer_shipping_postcode
            ? props.order_res.errors.website_customer_shipping_postcode[0] ?? ""
            : "",
        customer_shipping_company_error: props.order_res?.errors?.website_customer_shipping_company
            ? props.order_res.errors.website_customer_shipping_company[0] ?? ""
            : "",
        customer_shipping_country_error: props.order_res?.errors?.website_customer_shipping_country
            ? props.order_res.errors.website_customer_shipping_country[0] ?? ""
            : "",
        customer_billing_firstname_error: props.order_res?.errors?.website_customer_billing_firstname
            ? props.order_res.errors.website_customer_billing_firstname[0]
            : "",
        customer_billing_lastname_error: props.order_res?.errors?.website_customer_billing_lastname
            ? props.order_res.errors.website_customer_billing_lastname[0]
            : "",
        customer_billing_mobile_error: props.order_res?.errors?.website_customer_billing_mobile
            ? props.order_res.errors.website_customer_billing_mobile[0] ?? ""
            : "",
        customer_billing_email_error: props.order_res?.errors?.website_customer_billing_email
            ? props.order_res.errors.website_customer_billing_email[0] ?? ""
            : "",
        customer_billing_company_error: props.order_res?.errors?.website_customer_billing_company
            ? props.order_res.errors.website_customer_billing_company[0] ?? ""
            : "",
        customer_billing_country_error: props.order_res?.errors?.website_customer_billing_country
            ? props.order_res.errors.website_customer_billing_country[0] ?? ""
            : "",
        customer_billing_address1_error: props.order_res?.errors?.website_customer_billing_address1
            ? props.order_res.errors.website_customer_billing_address1[0] ?? ""
            : "",
        customer_billing_address2_error: props.order_res?.errors?.website_customer_billing_address2
            ? props.order_res.errors.website_customer_billing_address2[0] ?? ""
            : "",
        customer_billing_city_error: props.order_res?.errors?.website_customer_billing_city
            ? props.order_res.errors.website_customer_billing_city[0] ?? ""
            : "",
        customer_billing_state_error: props.order_res?.errors?.website_customer_billing_state
            ? props.order_res.errors.website_customer_billing_state[0] ?? ""
            : "",
        customer_billing_postcode_error: props.order_res?.errors?.website_customer_billing_postcode
            ? props.order_res.errors.website_customer_billing_postcode[0] ?? ""
            : "",
        is_guest_error: props.order_res?.errors?.is_guest
            ? props.order_res.errors.is_guest[0] ?? ""
            : "",
      }));
    }

    if (props.order_res?.status === 201 && paymentMethod !== "stripe") {
      handleOrderComplete(props.order_res?.status, props.order_res);
    }
  }, [props.order_res]);

  function handleOrderComplete(status, data) {
    try {
      const serializedData = JSON.stringify(data);
      if (data?.order?.data?.data?.payment_method === "square" || data?.order?.data?.data?.payment_method === "nmi" || data?.order?.data?.data?.payment_method === "boa") {
        const orderData = data?.order?.data?.data;
        let postData = {
          amount: orderData?.net_amount,
          orderNumber: orderData?.document_id,
          orderID: orderData?.id,
          givenName: orderData?.website_customer_billing_firstname,
          familyName: orderData?.website_customer_billing_lastname,
          email: orderData?.website_customer_shipping_email,
          phone: orderData?.website_customer_billing_mobile,
          city: orderData?.website_customer_billing_city,
          state: orderData?.website_customer_billing_state,
          stateCode: formData.customer_billing_state || formData.customer_shipping_state,
          postalCode: orderData?.website_customer_billing_postcode,
          countryCode: formData.customer_billing_country || formData.customer_shipping_country,
          addressLines: [orderData?.website_customer_billing_address1, orderData?.website_customer_billing_address2],
        }
        var encodedData = btoa(JSON.stringify(postData));
        setIframeURL(process.env.REACT_APP_SITE_PAYMENT_URL + "payment/" + orderData?.payment_method + "?data=" + encodeURIComponent(encodedData));
        setIsLoadingIFrame(true);
        setShowingIframe(true);
      } else {
        toast.success(`Order Created Successfully!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.resetOrder();
        resetForm();
        clearCart();
        navigate("/thank-you", { state: { orderData: serializedData } });
      }
    } catch (error) {
      console.error("Order creation failed:", error);
    }
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'redirect') {
        toast.success(`Order Created Successfully!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.resetOrder();
        resetForm();
        clearCart();
        window.location.href = event.data.url;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const [shippingCountries, setShippingCountries] = useState([]);
  const [shippingCities, setShippingCities] = useState([]);
  const [shippingStates, setShippingStates] = useState([]);

  const [billingCountries, setBillingCountries] = useState([]);
  const [billingCities, setBillingCities] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [type, setType] = useState("shipping");

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    setUserData(user_data?.user);
    props.countries();
    props.websitepaymentmethods();
    props.states("US");

    const detailsArray = Object.values(cartDetails);
    let products = [];
    detailsArray.forEach((data) => {
      products.push({
        website_product_id: data.id, //website product id
        qty: data.quantity,
        sell_price: data.price,
        gross_amount: data.value,
        net_amount: data.value,
      });
    });

    setWebsiteProducts(products);
    let sumWeight = 0;
    Object.values(cartDetails).forEach((cartDetail) => {
      sumWeight = sumWeight + (cartDetail.product.weight * cartDetail.quantity);
    });
    setProductWeight(sumWeight);

    // For Scroll Top and product weight calculate
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [cartDetails, setWebsiteProducts]);

  useEffect(() => {
    const countriesData = props.country_res.data;
    // Map countries to objects with both name and iso2 properties
    const countriesWithIso2 = countriesData.map((country) => ({
      name: country.name,
      iso2: country.iso2,
    }));
    // Set the countries with iso2 values in state
    setShippingCountries(countriesWithIso2);
    setBillingCountries(countriesWithIso2);
  }, [props.country_res]);

  useEffect(() => {
    // Fetch list of cities and states based on the selected country
    const fetchData = async () => {
      if (formData.customer_shipping_country) {
        // Use Promise.all to wait for both API calls to complete
        await Promise.all([
          props.states(formData.customer_shipping_country),
          // props.city(formData.customer_shipping_country),
        ]);
        setType("shipping");
      }
    };

    fetchData();
  }, [formData.customer_shipping_country]);

  useEffect(() => {
    // Fetch list of cities and states based on the selected country
    const fetchData = async () => {
      if (formData.customer_shipping_country) {
        if (formData.customer_shipping_state !== "" && formData.customer_shipping_state !== undefined) {
          if (formData.customer_shipping_state === 'CA') {
            // let total = totalPrice + fedexAmount;
            let total = totalPrice;
            const totalWithTax = (total * 10.25) / 100;
            setTaxPer(10.25)
            setTaxAmount(totalWithTax)
          } else {
            setTaxPer(0)
            setTaxAmount(0)
          }
          await Promise.all([
            props.city(
                formData.customer_shipping_country,
                formData.customer_shipping_state
            ),
          ]);
        }
        setType("shipping");
      }
    };

    fetchData();
  }, [formData.customer_shipping_state]);

  useEffect(() => {
    // Fetch list of cities and states based on the selected country
    const fetchData = async () => {
      if (formData.customer_billing_country) {
        // Use Promise.all to wait for both API calls to complete
        await Promise.all([
          props.states(formData.customer_billing_country),
          props.city(formData.customer_billing_country),
        ]);
        setType("billing");
      }
    };

    fetchData();
  }, [formData.customer_billing_country]);

  useEffect(() => {
    // Fetch list of cities and states based on the selected country
    const fetchData = async () => {
      if (formData.customer_billing_country) {
        if (
            formData.customer_shipping_state !== "" &&
            formData.customer_shipping_state !== undefined
        ) {
          await Promise.all([
            props.city(
                formData.customer_billing_country,
                formData.customer_billing_state
            ),
          ]);
          setType("billing");
        }
      }
    };

    fetchData();
  }, [formData.customer_billing_state]);

  useEffect(() => {
    const cityData = props.city_res.data;
    const stateData = props.states_res.data;

    if (type === "shipping") {
      if (stateData.length > 0) {
        const stateDataWithIso2 = stateData.map((city) => ({
          name: city.name,
          iso2: city.iso2,
        }));
        setShippingStates(stateDataWithIso2);
      }
      if (cityData.length > 0) {
        const cityDataWithIso2 = cityData.map((city) => ({
          name: city.name,
          iso2: city.name,
        }));
        setShippingCities(cityDataWithIso2);
      }
    } else if (type === "billing") {
      if (stateData.length > 0) {
        const stateDataWithIso2 = stateData.map((city) => ({
          name: city.name,
          iso2: city.iso2,
        }));
        setBillingStates(stateDataWithIso2);
      }
      if (cityData.length > 0) {
        const cityDataWithIso2 = cityData.map((city) => ({
          name: city.name,
          iso2: city.name,
        }));
        setBillingCities(cityDataWithIso2);
      }
    }
  }, [props.city_res, props.states_res, type]);

  //calling fedex Api
  useEffect(() => {
    if (
        formData.customer_shipping_country !== "" &&
        formData.customer_shipping_postcode.length > 4 &&
        productWeight !== 0
    ) {
      let fedexData = {
        type: "fedex",
        country_code: formData.customer_shipping_country,
        postal_code: formData.customer_shipping_postcode,
        unit: "LB",
        weight: productWeight,
      };

      // Create a new URLSearchParams object
      const queryParams = new URLSearchParams();

      // Iterate over the fedexData object and append each key-value pair to the URLSearchParams
      Object.entries(fedexData).forEach(([key, value]) => {
        queryParams.append(key, value);
      });

      // Use 'await' with your asynchronous operation, assuming props.fedex returns a Promise
      props.fedex(queryParams);
      props.websiteshippingmethods();
    }
  }, [
    formData.customer_shipping_country,
    formData.customer_shipping_postcode,
    productWeight,
  ]);

  // For Scroll Top and product weight calculate
  // useEffect(() => {
  //   let sumWeight = 0;
  //   Object.values(cartDetails).forEach((cartDetail) => {
  //     sumWeight = +sumWeight + +cartDetail.product.weight;
  //   });
  //   setProductWeight(sumWeight);
  //
  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };
  //   scrollToTop();
  // }, []);

  //set create order button show or not show
  useEffect(() => {
    if (formData.customer_shipping_firstname !== "" && formData.customer_shipping_lastname !== "" && formData.customer_shipping_email !== "" && formData.customer_shipping_mobile !== "" && formData.customer_shipping_address1 !== "" && formData.customer_shipping_city !== "" && formData.customer_shipping_state !== "" && formData.customer_shipping_country !== "" && fedexSelected !== "") {
      if (paymentMethod !== "") {
        setIsOrderDisabled(false);
      }
      setPaymentMethodShow(true);
    } else {
      setPaymentMethodShow(false);
    }
  }, [formData.customer_shipping_firstname, formData.customer_shipping_lastname, formData.customer_shipping_email, formData.customer_shipping_mobile, formData.customer_shipping_address1, formData.customer_shipping_city, formData.customer_shipping_state, formData.customer_shipping_country, fedexSelected, paymentMethod, cardPaymentResponse, setPaymentMethodShow]);

  const handleCheckboxChangeShipping = () => {
    setIsShippingManual(!isShippingManual);
  };
  const handleCheckboxChangeBilling = () => {
    setIsBillingManual(!isBillingManual);
  };

  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    // Fetch IP address
    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          fetch(`https://ipapi.co/${data.ip}/json/`)
              .then(response => response.json())
              .then(location => setCountryCode(location.country))
              .catch(error => console.error('Error fetching location:', error));
        })
        .catch(error => console.error('Error fetching IP address:', error));
  }, []);



  //bydefault select fedex shipping method
  useEffect(() => {
    if (formData.customer_shipping_country === "US" && props.shipping_method_res?.data?.length > 0) {
      setFedexSelected(props.shipping_method_res.data[0].name);
      handleChangeFedex(props.shipping_method_res.data[0].name, props.shipping_method_res.data[0].config?.amount);
    } else if (props.fedex_res?.data?.length > 0) {
      setFedexSelected(props.fedex_res.data[0].name);
      handleChangeFedex(props.fedex_res.data[0].name, props.fedex_res.data[0].amount);
    }
  }, [formData.customer_shipping_country, props.shipping_method_res, props.fedex_res]);


  return (
      <>
        {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <div className="modal-header">
                  <h2>ALERT</h2>
                  <span className="close" onClick={closeModal}>&times;</span>
                </div>
                <div className="modal-content scroll">
                  <h2>Your selected cart item price has been updated</h2>
                  <p>Please continue your shopping.</p>
                </div>
              </div>
            </div>
        )}
        <Layout childrenClasses="pt-0 pb-0">
          <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
            <div className="w-full mb-5">
              <PageTitle
                  title="Secure Checkout"
                  breadcrumb={[
                    { name: "home", path: "/" },
                    { name: "checkout", path: "/checkout" },
                  ]}
              />
            </div>
            {/*<div className="faster_checkout">*/}
            {/*  <p>For Faster Checkout :</p>*/}
            {/*  <Link to="/login?redirect=checkout" aria-label={ (userData?.firstname ? userData.firstname.substring(0, 10) : "signinbutton")}  >*/}
            {/*    {*/}
            {/*      (userData?.firstname*/}
            {/*          ? userData.firstname.substring(0, 10)*/}
            {/*          : "SIGN IN")*/}
            {/*    }*/}
            {/*  </Link>*/}
            {/*</div>*/}
            <div className="checkout-main-content w-full">
              <div className="container-x mx-auto">
                {/* <div className="w-full sm:mb-10 mb-5">
              <div className="sm:flex sm:space-x-[18px] s">
                <div className="sm:w-1/2 w-full mb-5 h-[70px]">
                  <a href="/login">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Log into your Account
                      </span>
                    </div>
                  </a>
                </div>
                <div className="flex-1 h-[70px]">
                  <a href="#">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Enter Coupon Code
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
                {/*<form onSubmit={handleSubmit} >*/}
                <form id="payment-form" >
                  <div className="w-full lg:flex lg:space-x-[30px]">
                    <div className="lg:w-1/1 w-full checkoutSecOne">
                      <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-3 pt-4 pl-5 my_heading_main">
                        Shipping Details
                      </h1>
                      <div className="form-area pl-5 pr-5">
                        <div className="flex space-x-2 items-center name_main">
                          <div className="w-full">
                            {/* <label htmlFor="customer_shipping_email">
                                Email Address*
                              </label> */}
                              <input
                                  id="customer_shipping_email"
                                  name="customer_shipping_email"
                                  type="email"
                                  placeholder="Email address*"
                                  value={formData.customer_shipping_email} // Uncomment this line
                                  onChange={handleChange}
                                  className="w-full h-[50px] border border-[#EDEDED] px-2"
                              />
                              {/* <span className="text-danger">
                                {formErrorData.customer_shipping_email_error}
                              </span> */}
                          </div>
                          

                          {/*<div className="flex-1">*/}
                          {/*  <InputCom*/}
                          {/*    label="Last Name*"*/}
                          {/*    placeholder="Demo Name"*/}
                          {/*    inputClasses="w-full h-[50px]"*/}
                          {/*  />*/}
                          {/*</div>*/}
                        </div>
                          <div className="flex space-x-2 items-center error_main">
                            <div className="w-full">
                              <span className="text-danger">
                                {formErrorData.customer_shipping_email_error}
                              </span>
                            </div>
                          </div>
                        <div className="flex space-x-2 items-center name_main">
                         
                          <div className="w-1/3">
                              {/* <label htmlFor="customer_shipping_firstname">
                                First Name*
                              </label> */}
                              <input
                                  id="customer_shipping_firstname"
                                  name="customer_shipping_firstname"
                                  placeholder="First Name*"
                                  value={formData.customer_shipping_firstname} // Uncomment this line
                                  onChange={handleChange}
                                  className="w-full h-[50px] border border-[#EDEDED] px-2"
                              />
                              {/* <span className="text-danger">
                                {formErrorData.customer_shipping_firstname_error}
                              </span> */}
                          </div>
                            <div className="w-1/3">
                              {/* <label htmlFor="customer_shipping_lastname">
                                Last Name*
                              </label> */}
                              <input
                                  id="customer_shipping_lastname"
                                  name="customer_shipping_lastname"
                                  placeholder="Last Name*"
                                  value={formData.customer_shipping_lastname} // Uncomment this line
                                  onChange={handleChange}
                                  className="w-full h-[50px] border border-[#EDEDED] px-2"
                              />
                              {/* <span className="text-danger">
                                {formErrorData.customer_shipping_lastname_error}
                              </span> */}
                            </div>
                            <div className="w-1/3">
                            {/* <label htmlFor="customer_shipping_company">
                              Company
                            </label> */}
                            <input
                                id="customer_shipping_company"
                                name="customer_shipping_company"
                                placeholder="Company"
                                value={formData.customer_shipping_company} // Uncomment this line
                                onChange={handleChange}
                                className="w-full h-[50px] border border-[#EDEDED] px-2"
                            />
                            {/* <span className="text-danger">
                              {formErrorData.customer_shipping_company}
                            </span> */}
                          </div>
                        </div>
                        <div className="flex space-x-2 items-center error_main">
                          <div className="w-1/3">
                              <span className="text-danger">
                                {formErrorData.customer_shipping_firstname_error}
                              </span>
                          </div>
                          <div className="w-1/3">
                              <span className="text-danger">
                                {formErrorData.customer_shipping_lastname_error}
                              </span>
                          </div>
                          <div className="w-1/3">
                            <span className="text-danger">
                              {formErrorData.customer_shipping_company}
                            </span>
                          </div>
                        </div>
                        {/* <div className="flex space-x-5 items-center mb-6">
                      <div className="flex-1">
                        <label htmlFor="customer_name">Email Address*</label>
                        <input
                          id="customer_email"
                          name="customer_email"
                          type="email"
                          placeholder="example@gmail.com"
                          value={formData.customer_email} // Uncomment this line
                          onChange={handleChange}
                          className="w-full h-[50px] border border-[#EDEDED] px-2"
                        />
                        <span className="text-danger">
                          {formErrorData.customer_email_error}
                        </span>
                      </div>
                    </div> */}
                        <div className="flex space-x-2 items-center name_main eamil">
                          
                          <div className="w-full">
                            <div className="w-full">
                              {/* <label htmlFor="customer_shipping_address1">
                                Address
                              </label> */}
                              <input
                                  id="customer_shipping_address1"
                                  name="customer_shipping_address1"
                                  type="text"
                                  placeholder="Address: Street no. Block, Nearest Landmark*"
                                  value={formData.customer_shipping_address1} // Uncomment this line
                                  onChange={handleChange}
                                  className="w-full h-[50px] border border-[#EDEDED] px-2"
                              />
                            </div>
                            {/* <span className="text-danger">
                              {formErrorData.customer_shipping_address2_error}
                            </span> */}
                          </div>
                          {/* <div className="w-1/3"> */}
                            {/* <label htmlFor="customer_shipping_address2">
                              {/* Second Address */}
                            {/* </label>  */}
                            {/* <input
                                id="customer_shipping_address2"
                                name="customer_shipping_address2"
                                type="text"
                                placeholder="Street address 2*"
                                value={formData.customer_shipping_address2} // Uncomment this line
                                onChange={handleChange}
                                className="w-full h-[50px] border border-[#EDEDED] px-2"
                            /> */}
                          {/* </div> */}
                        </div>
                        {/* <div className="flex space-x-5 items-center mb-6">


                        </div> */}

                        <div className="flex space-x-2 items-center error_main">
                          <div className="w-full">
                            <span className="text-danger">
                              {formErrorData.customer_shipping_address2_error}
                            </span>
                          </div>
                        </div>

                        <div className="flex space-x-2 items-start name_main">
                          <div className="w-1/3">
                            {/* <label htmlFor="Country">Country*</label> */}
                            <div className="w-full rounded slectBorder flex justify-between items-center">
                              <select
                                  className="text-[13px] rounded text-qgraytwo w-full px-2 h-[50px]"
                                  autoComplete="new-customer-shipping-country"
                                  id="customer_shipping_country"
                                  name="customer_shipping_country"
                                  value={formData.customer_shipping_country || "US"} // Set default value to 'US'
                                  onChange={dropDownhandleChange}
                                  style={{ fontWeight: 'bold', color: '#000' }}
                              >
                                <option value="" disabled>
                                  Select a country
                                </option>
                                {shippingCountries.map((country, index) => (
                                    <option key={index} dropDownName={country.name} value={country.iso2} >
                                      {country.name}
                                    </option>
                                ))}
                              </select>
                            </div>
                            {/* <span className="text-danger">
                              {formErrorData.customer_shipping_country_error}
                            </span> */}
                          </div>
                          <div className="w-1/3">
                            {/* <label htmlFor="shipping_state">State*</label> */}
                            <div className="w-full  rounded  slectBorder flex justify-between items-center">
                              <select
                                  className="text-[13px]  rounded text-qgraytwo w-full h-[50px] px-2"
                                  autoComplete="new-customer-shipping-state"
                                  onChange={dropDownhandleChange}
                                  id="customer_shipping_state"
                                  name="customer_shipping_state"
                                  value={formData.customer_shipping_state}
                                  style={{ fontWeight: 'bold', color: '#000' }}
                              >
                                <option value="" >
                                  Select a state
                                </option>
                                {shippingStates.map((state, index) => (
                                    <option key={index} dropDownName={state.name} value={state.iso2}>
                                      {state.name}
                                    </option>
                                ))}
                              </select>
                            </div>
                            {/* <span className="text-danger  mb-3">
                              {formErrorData.customer_shipping_state_error}
                            </span> */}
                          </div>
                          <div className="w-1/3">
                            <div className="w-full rounded slectBorder manually">
                              <>
                                {isShippingManual ? (
                                    <input
                                        id="customer_shipping_city"
                                        autoComplete="new-customer-shipping-city"
                                        name="customer_shipping_city"
                                        type="text"
                                        placeholder="Add city manually"
                                        value={formData.customer_shipping_city}
                                        onChange={handleChange}
                                        className="w-full h-[49px] border border-[#EDEDED] px-2"
                                    />
                                ) : (
                                    <select
                                        className="text-[13px] rounded text-qgraytwo w-full h-[49px] px-2"
                                        onChange={dropDownhandleChange}
                                        autocomplete="off"
                                        id="customer_shipping_city"
                                        name="customer_shipping_city"
                                        value={formData.customer_shipping_city}
                                        style={{ fontWeight: 'bold', color: '#000' }}
                                    >
                                      <option value="">
                                        Select a city
                                      </option>
                                      {shippingCities.map((city, index) => (
                                          <option key={index} dropDownName={city.name} value={city.iso2}>
                                            {city.name}
                                          </option>
                                      ))}
                                    </select>
                                )}
                                <label htmlFor="shipping_city" style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start'}}>
                                  Town / City* (Add Manually)
                                  <input
                                      className="checkBox_main"
                                      type="checkbox"
                                      name="shipping_city_add_manually"
                                      onChange={handleCheckboxChangeShipping}
                                      checked={isShippingManual}
                                      style={{ width: '35px', }}
                                  />
                                </label>
                              </>
                            </div>
                            {/* <span className="text-danger  mb-3">
                              {formErrorData.customer_shipping_city_error}
                            </span> */}
                          </div>
                        </div>

                        <div className="flex space-x-2 items-center error_main">
                          <div className="w-1/3">
                            <span className="text-danger">
                              {formErrorData.customer_shipping_country_error}
                            </span>
                          </div>
                          <div className="w-1/3">
                            <span className="text-danger ">
                              {formErrorData.customer_shipping_state_error}
                            </span>
                          </div>
                          <div className="w-1/3">
                            <span className="text-danger ">
                              {formErrorData.customer_shipping_city_error}
                            </span>
                          </div>
                        </div>

                        <div className="flex space-x-2 items-start name_main">
                          <div className="w-1/3">
                            {/* <label htmlFor="customer_shipping_postcode">
                              Zip Code*
                            </label> */}
                            <input
                                id="customer_shipping_postcode"
                                name="customer_shipping_postcode"
                                type="text"
                                placeholder="Zip Code*"
                                value={formData.customer_shipping_postcode} // Uncomment this line
                                onChange={handleChange}
                                className="w-full h-[50px] border border-[#EDEDED] px-2"
                            />
                            {/* <span className="text-danger">
                              {formErrorData.customer_shipping_postcode_error}
                            </span> */}
                          </div>
                          <div className="w-1/3">
                            {/* <label htmlFor="customer_shipping_mobile">
                              Phone Number*
                            </label> */}
                            <input
                                id="customer_shipping_mobile"
                                name="customer_shipping_mobile"
                                type="text"
                                placeholder="Phone Number*"
                                value={formData.customer_shipping_mobile} // Uncomment this line
                                onChange={handleChange}
                                className="w-full h-[50px] border border-[#EDEDED] px-2"
                            />
                            {/* <span className="text-danger">
                              {formErrorData.customer_shipping_mobile_error}
                            </span> */}
                          </div>
                          <div className="w-1/3"></div>
                        </div>
                        
                        <div className="flex space-x-2 items-center error_main">
                          <div className="w-1/3">
                            <span className="text-danger">
                              {formErrorData.customer_shipping_postcode_error}
                            </span>
                          </div>
                          <div className="w-1/3">
                            <span className="text-danger">
                              {formErrorData.customer_shipping_mobile_error}
                            </span>
                          </div>
                          <div className="w-1/3"></div>
                        </div>

                        {/* <div className="flex space-x-5 items-center mb-6">

                        </div> */}

                        <div className="flex space-x-5 items-center mb-1">
                          {/*<div className="w-1/2">*/}
                          {/*  <span className="text-danger  mb-3">*/}
                          {/*    {formErrorData.customer_shipping_state_error}*/}
                          {/*  </span>*/}
                          {/*</div>*/}
                          {/*<div className="w-1/2">*/}
                          {/*  <span className="text-danger  mb-3">*/}
                          {/*    {formErrorData.customer_shipping_city_error}*/}
                          {/*  </span>*/}
                          {/*</div>*/}
                        </div>
                        {/*<div className="flex space-x-2 items-center mb-10">*/}
                        {/*  <div>*/}
                        {/*    <input*/}
                        {/*      type="checkbox"*/}
                        {/*      id="create"*/}
                        {/*      onChange={handleChange}*/}
                        {/*      name="is_guest"*/}
                        {/*      checked={formData.is_guest} // Reflect the state of the checkbox*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*  <label*/}
                        {/*    htmlFor="create"*/}
                        {/*    className="text-qblack text-[15px] select-none"*/}
                        {/*  >*/}
                        {/*    Create an account?*/}
                        {/*  </label>*/}
                        {/*  <span className="text-danger">*/}
                        {/*    {formErrorData.is_guest_error}*/}
                        {/*  </span>*/}
                        {/*</div>*/}

                        <div>
                          <hr />
                          <h1 className="text-2xl text-qblack font-medium mb-2 pt-4 my_heading_main">
                            Billing Details
                          </h1>
                          <div className="flex space-x-2 items-center mb-2">
                            <input
                                className="checkBox_mains"
                                type="checkbox"
                                id="address"
                                onChange={() =>
                                    setShowBillingDetails(!showBillingDetails)
                                }
                                checked={showBillingDetails}
                            />
                            <label
                                htmlFor="address"
                                className="text-qblack text-[15px] select-none"
                            >
                              Bill to a different address
                            </label>
                          </div>
                          {showBillingDetails && (
                              <div className="BillingDiv">
                                <div className="flex space-x-2 items-start name_main name_main">
                                  {/* <div className="w-1/3"> */}
                                    {/* <label htmlFor="customer_name">
                                      Email Address*
                                    </label> */}
                                    {/* <input
                                        id="customer_billing_email"
                                        name="customer_billing_email"
                                        type="email"
                                        placeholder="Email address*"
                                        value={formData.customer_billing_email} // Uncomment this line
                                        onChange={handleChange}
                                        className="w-full h-[50px] border border-[#EDEDED] px-2"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_email_error}
                                    </span>
                                  </div> */}

                                  <div className="w-1/3">
                                    {/* <label htmlFor="billing_first_name">
                                      First Name*
                                    </label> */}
                                    <input
                                        id="customer_billing_firstname"
                                        name="customer_billing_firstname"
                                        placeholder="First Name*"
                                        value={formData.customer_billing_firstname} // Uncomment this line
                                        onChange={handleChange}
                                        className="w-full h-[50px] border border-[#EDEDED] px-2"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_firstname_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3">
                                    {/* <label htmlFor="last_name">Last Name*</label> */}
                                    <input
                                        id="customer_billing_lastname"
                                        name="customer_billing_lastname"
                                        placeholder="Last Name*"
                                        value={formData.customer_billing_lastname} // Uncomment this line
                                        onChange={handleChange}
                                        className="w-full h-[50px] border border-[#EDEDED] px-2"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_lastname_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3">
                                    {/* <label htmlFor="customer_billing_company">
                                      Company
                                    </label> */}
                                    <input
                                        id="customer_billing_company"
                                        name="customer_billing_company"
                                        placeholder="Company"
                                        value={formData.customer_billing_company} // Uncomment this line
                                        onChange={handleChange}
                                        className="w-full h-[50px] border border-[#EDEDED] px-2"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_company_error}
                                    </span>
                                  </div>
                                </div>
                                
                                <div className="flex space-x-2 items-start name_main eamil">
                                  
                                  <div className="w-full">
                                    <div className="w-full">
                                      {/* <label htmlFor="customer_billing_address1">
                                        Address
                                      </label> */}
                                      <input
                                          id="customer_billing_address1"
                                          name="customer_billing_address1"
                                          type="text"
                                          placeholder="Address: Street no. Block, Nearest Landmark*"
                                          value={formData.customer_billing_address1} // Uncomment this line
                                          onChange={handleChange}
                                          className="w-full h-[50px] px-2 border border-[#EDEDED]"
                                      />
                                    </div>
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_address1_error}
                                    </span>
                                  </div>
                                  {/* <div className="w-1/3">
                                    <div className="w-full"> */}
                                      {/* <label htmlFor="customer_billing_address2">
                                        {/* Second Address */}
                                      {/* </label> */}
                                      {/* <input
                                          id="customer_billing_address2"
                                          name="customer_billing_address2"
                                          type="text"
                                          placeholder="Street address 2*"
                                          value={formData.customer_billing_address2} // Uncomment this line
                                          onChange={handleChange}
                                          className="w-full h-[50px] px-2 border border-[#EDEDED]"
                                      />
                                    </div>
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_address2_error}
                                    </span>
                                  </div> */}
                                </div>

                                <div className="flex space-x-2 items-start name_main">
                                  <div className="w-1/3">
                                    {/* <label>Country*</label> */}

                                    <div className="w-full rounded  slectBorder flex justify-between items-center">
                                      <select
                                          className="text-[13px] rounded h-[50px] px-2 text-qgraytwo w-full"
                                          id="customer_billing_country"
                                          name="customer_billing_country"
                                          value={formData.customer_billing_country}
                                          onChange={dropDownhandleChange}
                                          style={{ fontWeight: 'bold', color: '#000' }}
                                      >
                                        <option value="" disabled >
                                          Select a country
                                        </option>
                                        {billingCountries.map((country, index) => (
                                            <option key={index} dropDownName={country.name} value={country.iso2}>
                                              {country.name}
                                            </option>
                                        ))}
                                      </select>
                                    </div>
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_country_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3">
                                    {/* <label htmlFor="State">State*</label> */}
                                    <div className="w-full  rounded  slectBorder flex justify-between items-center">
                                      <select
                                          className="text-[13px] rounded text-qgraytwo w-full h-[50px] px-2"
                                          onChange={dropDownhandleChange}
                                          id="customer_billing_state"
                                          name="customer_billing_state"
                                          value={formData.customer_billing_state}
                                          style={{ fontWeight: 'bold', color: '#000' }}
                                      >
                                        <option value="" disabled>
                                          Select a state
                                        </option>
                                        {billingStates.map((state, index) => (
                                            <option key={index} dropDownName={state.name} value={state.iso2}>
                                              {state.name}
                                            </option>
                                        ))}
                                      </select>
                                    </div>
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_state_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3">
                                    <div className="w-full rounded slectBorder manually">
                                      {isBillingManual ? (
                                          <input
                                              id="customer_billing_city"
                                              name="customer_billing_city"
                                              type="text"
                                              placeholder="Add city manually"
                                              value={formData.customer_billing_city}
                                              onChange={handleChange}
                                              className="w-full h-[49px] border border-[#EDEDED] px-2"
                                          />
                                      ) : (
                                          <select
                                              className="text-[13px] rounded text-qgraytwo w-full h-[49px] px-2"
                                              onChange={dropDownhandleChange}
                                              id="customer_billing_city"
                                              name="customer_billing_city"
                                              value={formData.customer_billing_city}
                                              style={{ fontWeight: 'bold', color: '#000' }}
                                          >
                                            <option value="">
                                              Select a city
                                            </option>
                                            {billingCities.map((city, index) => (
                                                <option key={index} dropDownName={city.name} value={city.iso2}>
                                                  {city.name}
                                                </option>
                                            ))}
                                          </select>
                                      )}
                                      <span className="text-danger">
                                        {formErrorData.customer_billing_city_error}
                                      </span>
                                      <label htmlFor="customer_billing_city" style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start' }}>
                                        Town / City* (Add Manually)
                                        <input
                                            className="checkBox_main"
                                            type="checkbox"
                                            name="customer_billing_city"
                                            onChange={handleCheckboxChangeBilling}
                                            checked={isBillingManual}
                                            style={{ width: '35px', }}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex space-x-2 items-start name_main">
                                  <div className="w-1/3">
                                    {/* <label htmlFor="customer_billing_postcode">
                                      Zip Code*
                                    </label> */}
                                    <input
                                        id="customer_billing_postcode"
                                        name="customer_billing_postcode"
                                        type="text"
                                        placeholder="Zip Code*"
                                        value={formData.customer_billing_postcode}
                                        onChange={handleChange}
                                        className="w-full h-[50px] px-2 border border-[#EDEDED]"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_postcode_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3">
                                    {/* <label htmlFor="customer_mobile">
                                      Phone Number*
                                    </label> */}
                                    <input
                                        id="customer_billing_mobile"
                                        name="customer_billing_mobile"
                                        type="text"
                                        placeholder="Phone Number*"
                                        value={formData.customer_billing_mobile}
                                        onChange={handleChange}
                                        className="w-full h-[50px] border border-[#EDEDED] px-2"
                                    />
                                    <span className="text-danger">
                                      {formErrorData.customer_billing_mobile_error}
                                    </span>
                                  </div>
                                  <div className="w-1/3"></div>
                                </div>
                              </div>
                          )}
                        </div>
                      </div>
                      <div className="w-full  pl-5 pr-5 pb-4 mt-4">
                        <h1 className="sm:text-2xl text-xl text-qblack font-medium my_heading_main">
                          Shipping Method
                        </h1>
                        <p
                            className="text-[13px] mb-3 delievery_method"
                            style={{ color: "#08456B" }}
                        >
                          Choose Your Preferred Delivery Method
                        </p>
                        <span className="text-danger">
                          {formErrorData.fedex_error}
                        </span>
                        <div
                            style={{
                              padding: "20px",
                              backgroundColor: "#fff",
                              borderRadius: 10
                            }}
                        >
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                                borderBottom: "1px solid #000",
                              }}
                          >
                            <div>
                              <p style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 12 }}>
                                Service Name
                              </p>
                            </div>
                            <p style={{ textAlign: "right", fontWeight: "bold" }}>
                              Cost
                            </p>
                          </div>

                          {formData.customer_shipping_country === "US" &&
                          props.shipping_method_res?.data?.map((data, index) => (
                              <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    borderBottom: "1px solid #EDEDED",
                                    padding: "9px",
                                  }}
                              >
                                <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      alignItems: "baseline",
                                    }}
                                >
                                  <input
                                      type="checkbox"
                                      style={{ marginLeft: -10, color: "#08456B" }}
                                      checked={fedexSelected === data.name}
                                      onChange={() => handleChangeFedex(data.name, data.config?.amount)}
                                      aria-label={data.name}
                                  />
                                  <p style={{ textAlign: "left", marginLeft: 10, fontWeight: "bold", whiteSpace: 'nowrap' }}>
                                    {data.name}
                                  </p>
                                </div>
                                <p style={{ textAlign: "right" }}>
                                  ${data.config?.amount}
                                </p>
                              </div>
                          ))
                          }

                          {props.fedex_res?.data?.map((data, index) => (
                              <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    borderBottom: "1px solid #EDEDED",
                                    padding: "9px",
                                  }}
                              >
                                <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      alignItems: "baseline",
                                    }}
                                >
                                  <input
                                      type="checkbox"
                                      style={{ marginLeft: -10, color: "#08456B" }}
                                      checked={fedexSelected === data.name}
                                      onChange={() => handleChangeFedex(data.name, data.amount)}
                                      aria-label={data.name}
                                  />
                                  <p style={{ textAlign: "left", marginLeft: 10, fontWeight: "bold", whiteSpace: 'nowrap' }}>
                                    {data.name}
                                  </p>
                                </div>
                                <p style={{ textAlign: "right" }}>
                                  ${data.amount}
                                </p>
                              </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full checkoutSecOne ">
                      <h1 className="order_summary">
                        Order Summary
                      </h1>
                      <div className="w-full py-[20px] pl-5 pr-5">

                        {/* <div className="sub-total pt-totals pb-2 rounded-t-lg">
                          <div className=" flex justify-between pl-3 pr-3  pt-2">
                            <p className="text-[13px] font-semibold text-qblack uppercase">
                              Product
                            </p>
                            <p className="text-[13px] font-semibold text-qblack uppercase">
                              total
                            </p>
                          </div>
                        </div> */}
                        {/* <div className="product-list w-full mb-[10px] pl-3 pr-3 pb-2 rounded-b-lg">
                          <ul className="flex flex-col space-y-5">
                            {Object.values(cartDetails).map((cartDetail) => (
                                <li>
                                  <div className="flex justify-between items-center">
                                    <div>
                                      <h4 className="text-[15px] text-qblack mb-2.5">
                                        {cartDetail.product.mpn}
                                        <sup className="text-[13px] text-qgray ml-2 mt-2">
                                          x{cartDetail.quantity}
                                        </sup>
                                      </h4>
                                      <p className="text-[13px] text-qgray">
                                        {cartDetail.title.length > 30
                                            ? cartDetail.title.substring(0, 30) + "..."
                                            : cartDetail.title}
                                      </p>
                                    </div>
                                    <div>
                                <span className="text-[15px] text-qblack font-medium">
                                  ${cartDetail.price * cartDetail.quantity}
                                </span>
                                    </div>
                                  </div>
                                </li>
                            ))}
                          </ul>
                        </div> */}

                        <Accordion defaultActiveKey="0" className="custom_accordian_cls">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>{Object.keys(cartDetails)?.length} Items</Accordion.Header>
                            <Accordion.Body>
                              {/* <div className="sub-total pt-totals pb-2 rounded-t-lg">
                              <div className=" flex justify-between pl-3 pr-3  pt-2">
                                <p className="text-[13px] font-semibold text-qblack uppercase">
                                  Product
                                </p>
                                <p className="text-[13px] font-semibold text-qblack uppercase">
                                  total
                                </p>
                              </div>
                            </div> */}
                              <ul className="flex flex-col space-y-5">
                                {Object.values(cartDetails).map((cartDetail) => (
                                    <li>
                                      <div className="flex justify-between items-center">
                                        <div>
                                          <h4 className="text-[15px] text-qblack mb-1">
                                            {cartDetail.product.mpn}
                                            <sup className="text-[13px] text-qgray ml-2 mt-2">
                                              x{cartDetail.quantity}
                                            </sup>
                                          </h4>
                                          <p className="text-[13px] text-qgray">
                                            {cartDetail.title.length > 30
                                                ? cartDetail.title.substring(0, 30) + "..."
                                                : cartDetail.title}
                                          </p>
                                        </div>
                                        <div>
                                  <span className="text-[15px] text-qblack font-medium">
                                    ${cartDetail.price * cartDetail.quantity}
                                  </span>
                                        </div>
                                      </div>
                                    </li>
                                ))}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <div className="mt-[10px] mb-[10px]">
                          <div className=" flex justify-between">
                            <p className="text-[13px] font-semibold text-qblack subtotal_label">
                              Subtotal
                            </p>
                            <p className="text-[15px] font-semibold text-qblack uppercase subtotal_amount">
                              ${totalPrice.toFixed(2)}
                            </p>
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="sub-total">
                            <div className=" flex justify-between mt-[10px] mb-[10px]">
                              <div>
                                <p className="text-[13px] font-semibold text-qblack subtotal_label">
                                  Shipping
                                </p>
                              </div>
                              <p className="text-[15px] font-semibold text-qblack subtotal_amount">
                                ${fedexAmount}
                              </p>
                            </div>
                            <div className=" flex justify-between mt-[10px] mb-[10px]">
                              <div>
                                <p className="text-[13px] font-semibold text-qblack subtotal_label">
                                  Tax
                                </p>
                              </div>
                              <p className="text-[15px] font-semibold text-qblack subtotal_amount">
                                ({taxPer}%) ${parseFloat(taxAmount).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                        <div className="mt-[10px]">
                          <div className=" flex justify-between mb-2">
                            <p className="text-2xl font-medium text-qblack total_label">
                              Total
                            </p>
                            <p className="text-2xl font-medium total_amount">
                              $
                              {`${(
                                  parseFloat(totalPrice) + parseFloat(fedexAmount) + parseFloat(taxAmount)
                              ).toFixed(2)}`}
                            </p>
                          </div>
                        </div>

                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                        {paymentMethodShow && (
                            <div className="shipping mt-[20px]">
                              <div className="payment_methods">
                                <p className="text-2xl font-medium text-qblack my_heading_main">
                                  Payment Methods
                                </p>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/payment_icons.webp`} alt="payment-icons" />
                              </div>
                              <ul
                                  className="flex flex-col space-y-1 "
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                  }}
                              >
                                {Array.isArray(props.payment_method_res.data) &&
                                props.payment_method_res.data.map((paymentMethodItem, index) => {
                                  if (countryCode === 'PK' && paymentMethodItem.type === 'bank_transfer') {
                                    return (
                                        <li key={index}>
                                          <div className="flex space-x-2.5 items-center">
                                            <div className="input-radio">
                                              <input
                                                  type="radio"
                                                  name="paymentType"
                                                  className="accent-blue-500"
                                                  id={`paymentMethod-${index}`}
                                                  value={paymentMethodItem.type}
                                                  checked={paymentMethod === paymentMethodItem.type} // Check if this is the default payment method
                                                  onChange={(e) => handlePaymentTypeChange(e.target.value, paymentMethodItem)}
                                              />
                                            </div>
                                            <label
                                                htmlFor={`paymentMethod-${index}`}
                                                className="text-[18px] text-normal text-qblack"
                                            >
                                              {paymentMethodItem.name}
                                            </label>
                                          </div>
                                        </li>
                                    );
                                  } else if (true) {
                                    return (
                                        <li key={index}>
                                          <div className="flex space-x-2.5 items-center">
                                            <div className="input-radio">
                                              <input
                                                  type="radio"
                                                  name="paymentType"
                                                  className="accent-blue-500"
                                                  id={`paymentMethod-${index}`}
                                                  value={paymentMethodItem.type}
                                                  checked={paymentMethod === paymentMethodItem.type} // Check if this is the default payment method
                                                  onChange={(e) => handlePaymentTypeChange(e.target.value, paymentMethodItem)}
                                              />
                                            </div>
                                            <label
                                                htmlFor={`paymentMethod-${index}`}
                                                className="text-[18px] text-normal text-qblack"
                                            >
                                              {paymentMethodItem.name}
                                            </label>
                                          </div>
                                        </li>
                                    );
                                  }
                                  return null; // Return null for other cases
                                })}
                              </ul>
                            </div>
                        )}

                        <div className="mt-[10px]">
                          {(paymentMethod === 'square' && false) && (
                              <SquarePaymentForm
                                  totalPrice={totalPrice}
                                  fedexAmount={fedexAmount}
                                  paymentMethod={paymentMethod}
                                  paymentMethodData={paymentMethodData}
                                  name={formData.first_name + " " + formData.last_name}
                                  email={formData.customer_email}
                                  phone={formData.customer_mobile}
                                  addressLines={formData.customer_shipping_address1}
                                  city={formData.customer_shipping_city}
                                  state={formData.customer_shipping_state}
                                  countryCode={formData.customer_shipping_country}
                                  setCardPaymentResponse={setCardPaymentResponse}
                                  setCardPaymentStatus={setCardPaymentStatus}
                                  taxAmount={taxAmount}
                                  taxPer={taxPer}
                                  discountAmount={discountAmount}
                              />
                          )}
                          {paymentMethod === 'quickbook' && (
                              <QuickBookPaymentForm
                                  totalPrice={totalPrice}
                                  fedexAmount={fedexAmount}
                                  paymentMethod={paymentMethod}
                                  paymentMethodData={paymentMethodData}
                                  name={formData.first_name + " " + formData.last_name}
                                  email={formData.customer_email}
                                  phone={formData.customer_mobile}
                                  addressLines={formData.customer_shipping_address1}
                                  countryCode={formData.customer_shipping_country}
                                  setCardPaymentResponse={setCardPaymentResponse}
                                  setCardPaymentStatus={setCardPaymentStatus}
                                  taxAmount={taxAmount}
                                  taxPer={taxPer}
                                  discountAmount={discountAmount}
                                  username={formData.customer_shipping_firstname + " " + formData.customer_shipping_lastname}
                                  postcode={formData.customer_shipping_postcode}
                                  country={formData.customer_shipping_country}
                                  state={formData.customer_shipping_state}
                                  address1={formData.customer_shipping_address1}
                                  city={formData.customer_shipping_city}
                              />
                          )}
                          {/*{paymentMethod === 'stripe' && (*/}
                          {/*  <StripePaymentForm*/}
                          {/*    totalPrice={totalPrice}*/}
                          {/*    fedexAmount={fedexAmount}*/}
                          {/*    paymentMethod={paymentMethod}*/}
                          {/*    paymentMethodData={paymentMethodData}*/}
                          {/*    name={formData.customer_shipping_firstname + " " + formData.customer_shipping_lastname}*/}
                          {/*    email={formData.customer_shipping_email}*/}
                          {/*    phone={formData.customer_mobile}*/}
                          {/*    addressLines={formData.customer_shipping_address1}*/}
                          {/*    countryCode={formData.customer_shipping_country}*/}
                          {/*    setCardPaymentResponse={setCardPaymentResponse}*/}
                          {/*    setCardPaymentStatus={setCardPaymentStatus}*/}
                          {/*    cardPaymentStatus={cardPaymentStatus}*/}
                          {/*    taxAmount={taxAmount}*/}
                          {/*    taxPer={taxPer}*/}
                          {/*    discountAmount={discountAmount}*/}
                          {/*    username={formData.customer_shipping_firstname + " " + formData.customer_shipping_lastname}*/}
                          {/*    postcode={formData.customer_shipping_postcode}*/}
                          {/*    country={formData.customer_shipping_country}*/}
                          {/*    state={formData.customer_shipping_state}*/}
                          {/*    address1={formData.customer_shipping_address1}*/}
                          {/*    city={formData.customer_shipping_city}*/}
                          {/*  />*/}
                          {/*)}*/}
                        </div>


                        {(paymentMethod === "stripe" || (props.order_res?.status === 201 && (paymentMethod === "square" || paymentMethod === "nmi" || paymentMethod === "boa"))) ? null : (
                            <button
                                className={`w-full h-[50px] ${
                                    isOrderDisabled ? "bg-[#F6F6F6]" : "black-btn"
                                } flex justify-center items-center`}
                                type="button"
                                id={paymentMethod === "quickbook" ? "quickbook-button" : "card-button"}
                                onClick={handleSubmit}
                                // disabled={isOrderDisabled}
                            >
                              {(paymentMethod === "square" || paymentMethod === "nmi" || paymentMethod === "boa") ? "Proceed to Payment" : "Place Order Now"}
                            </button>
                        )}
                        {(props.order_res?.status === 201 && (paymentMethod === "square" || paymentMethod === "nmi" || paymentMethod === "boa") && showingIframe) &&
                        <>
                          {isLoadingIFrame && <Loader />}
                          <iframe src={iframeURL} onLoad={() => { setIsLoadingIFrame(false); }} height={400} width={350} />
                        </>
                        }
                        {/*<div className="mt-[30px]">*/}
                        {/*  <div className=" flex justify-between mb-5">*/}
                        {/*    <p className="text text-qblack">*/}
                        {/*      *We Only Accept*/}
                        {/*    </p>*/}
                        {/*    <p className="text-2xl font-medium text-qred">*/}
                        {/*      <img width="210" height="28" src="/assets/images/payment-getways.png" alt="payment-getways"/>*/}
                        {/*    </p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Layout>
      </>

  );
};
const mapStateToProps = (state) => ({
  order_res: state.common.orderRes,
  country_res: state.common.countryRes,
  states_res: state.common.statesRes,
  city_res: state.common.cityRes,
  fedex_res: state.common.fedexRes,
  payment_method_res: state.common.paymentMethodRes,
  shipping_method_res: state.common.shippingMethodRes,
  order_update_res: state.common.orderUpdateRes,
  order_price_check_res: state.common.orderPriceCheckRes,

});

const mapDispatchToProps = (dispatch) => ({
  orderNow: (jsondata) => dispatch(orderNow(jsondata)),
  resetOrder: () => dispatch(resetOrder()),
  countries: () => dispatch(countries()),
  city: (country, state) => dispatch(city(country, state)),
  states: (country) => dispatch(states(country)),
  fedex: (data) => dispatch(fedex(data)),
  websitepaymentmethods: () => dispatch(websitepaymentmethods()),
  websiteshippingmethods: () => dispatch(websiteshippingmethods()),
  orderUpdate: (id,data) => dispatch(orderUpdate(id,data)),
  productPriceCheck: (data) => dispatch(productPriceCheck(data)),


  // resetPaymentCard: () => dispatch(resetPaymentCard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheakoutPage);


