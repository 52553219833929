import { useState, useEffect } from "react";
import { useShoppingCart } from 'use-shopping-cart';

export default function InputQuantityCom(props) {
  const { originalQuantity, productId } = props;
  const [quantity, setQuantity] = useState(originalQuantity);

  useEffect(() => {
    setQuantity(originalQuantity); // Update the local state when the originalQuantity prop changes
  }, [originalQuantity]);

  const { incrementItem, decrementItem, setItemQuantity } = useShoppingCart();

  const increment = () => {
    setQuantity((prev) => prev + 1);
    incrementItem(productId);
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
      decrementItem(productId);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow the user to clear the input field
    if (value === "") {
      setQuantity(""); // Set to empty string to allow clearing
    } else if (!isNaN(value) && parseInt(value) >= 1) {
      const newQuantity = parseInt(value);

      if (newQuantity >= 1) {
        setItemQuantity(productId, newQuantity);
        setQuantity(newQuantity); // Update the input field state with the valid number
      }
    }
  };

  const handleBlur = () => {
    // If the field is empty after editing, set it back to the originalQuantity or 1
    if (quantity === "" || quantity < 1) {
      setQuantity(originalQuantity || 1);
      setItemQuantity(productId, originalQuantity || 1);
    }
  };

  return (
      <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
        <div className="flex justify-between items-center w-full">
          <button
              onClick={decrement}
              type="button"
              className="text-base text-qgray"
          >
            -
          </button>
          <input
              type="text"
              value={quantity}
              onChange={handleInputChange}
              onBlur={handleBlur} // Handle resetting the input when it loses focus
              className="text-qblack text-center"
              style={{ width: "40px", fontSize: "16px", border: "none", outline: "none" }}
          />
          <button
              onClick={increment}
              type="button"
              className="text-base text-qgray"
          >
            +
          </button>
        </div>
      </div>
  );
}
