import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ClientReviewModal.css";
import { ImCross } from "react-icons/im";
import { FaCircleCheck } from "react-icons/fa6";
import { MdOutlineInfo } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import {Link} from "react-router-dom";

const ClientReviewModal = ({
  open,
  ReviewModalClose, // This should match the prop name used
  toggleInfo,
  handleToggleInfo,
  productData,
  openLearnMoreModal,
}) => {
  if (!productData) return null;

  return (
    <Modal
      show={open}
      onHide={ReviewModalClose}
      backdrop="true"
      size="xl"
      onExited={ReviewModalClose}
      className="client_review_modal"
      centered
    >
      <Modal.Header>
        <h3>Product Review</h3>
        <Button onClick={ReviewModalClose}>
          <ImCross />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="review_left">
          <Link to={`/${productData.url}`}>
            <img
                src={
                  productData.thumbnailImage ||
                  `${process.env.PUBLIC_URL}/assets/images/product-img-8.jpg`
                }
                alt="review-product"
            />
          </Link>
        </div>
        <div className="review_right">
          <div className="top">
            <p>{productData.customer_name}</p>
            <div className="inner">
              <div className="verified_purchase">
                <FaCircleCheck />
                <p>Verified purchase</p>
              </div>
              <MdOutlineInfo onClick={handleToggleInfo} />
            </div>
          </div>
          <div className="bottom">
            <div className="star">
              {Array.from({ length: productData.product_rating }).map(
                (_, index) => (
                  <IoIosStar key={index} />
                )
              )}
            </div>
            <span>{productData.review_date}</span>
          </div>
          <div className="discription">
            <p>{productData.remarks}</p>
          </div>
          {toggleInfo && (
            <div className="overlay_div">
              <div className="cross_icon">
                <RxCross2 onClick={handleToggleInfo} />
              </div>
              <p>This review was marked as verified by the store owner.</p>
              <p>This review was imported from an external source.</p>
              <Button
                className="yellow-btn"
                onClick={() => openLearnMoreModal()}
              >
                Learn more
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClientReviewModal;
