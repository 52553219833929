import React, {useEffect, useState} from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import {connect} from "react-redux";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import datas from "../../data/products.json";
import {
    getHomeBanner,
    getHomeTopManufacture,
    getHomeTopSellerCategories,
    getHomeTopsellercategories
} from "../../actions/homeAction";
import {toast} from "react-toastify";


const SellerCategoriesProducts = (props) => {
    const { className, sectionTitle , type,} = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [transformedProducts, setTransformedProducts] = useState([]);


    const handleTabChange = (event, newValue) => {
        const data = props.top_categories?.data;
        const selectedCategory = tabs[newValue];
        const selectedCategoryProducts = getProductsByCategory(data, selectedCategory);

        transformedProductData(selectedCategoryProducts);

        setSelectedTab(newValue);
    };


    async function transformedProductData(selectedCategoryProducts) {
        if (selectedCategoryProducts?.website_products && selectedCategoryProducts?.website_products.length > 0) {
            const transformedArray = await Promise.all(selectedCategoryProducts.website_products.map(async (data) => {
                // Find the base image
                let baseImage = null;
                let baseThumbnailImage = null;

                if (data.product.images) {
                    const images = Object.values(data.product.images);

                    for (const image of images) {
                        const customProperties = image.custom_properties;

                        if (customProperties && customProperties.image_type && customProperties.image_type.includes("Base")) {
                            baseImage = image.original_url;
                            if(image.thumbnail !== null && image.thumbnail !== undefined && image.thumbnail !== ''){
                                baseThumbnailImage = image.thumbnail;
                            }
                            break;
                        }
                    }
                }

                if (baseImage == null) {
                    if (selectedCategoryProducts?.website_category?.image) {
                        if (selectedCategoryProducts?.website_category?.image.length > 0) {
                            let categoryMedia = selectedCategoryProducts.website_category.image[0];
                            baseImage = categoryMedia?.original_url;
                            if (categoryMedia.thumbnail !== null && categoryMedia.thumbnail !== undefined && categoryMedia.thumbnail !== '') {
                                baseThumbnailImage = categoryMedia.thumbnail;
                            }
                        }
                    }
                }

                let sell_price = '';

                const currentDate = new Date();
                const specialPriceTo = new Date(data.special_price_to);
                const specialPriceFrom = new Date(data.special_price_from);

                if (data.special_price !== null && data.special_price != 0 && currentDate >= specialPriceFrom && currentDate <= specialPriceTo) {
                    sell_price = data.special_price;
                } else {
                    sell_price = data.sell_price;
                }

                return {
                    id: data.id,
                    product_id: data?.product.id,
                    redirect_url: data?.redirect_url,
                    website_category: selectedCategoryProducts?.website_category,
                    short_description: data.short_description,
                    long_description: data.long_description,
                    cost_price: data.cost_price,
                    sell_price: data.sell_price,
                    slash_price: data.slash_price,
                    special_price: data.special_price,
                    discount: data.discount,
                    qty: data.qty,
                    meta_title: data.meta_title,
                    meta_description: data.meta_description,
                    meta_keywords: data.meta_keywords,
                    product: data.product,
                    attributes: data.attributes,
                    url: data.url,
                    title: data.title,
                    thumbnailImage: baseThumbnailImage,
                    image: baseImage, // Use the base image if available, otherwise fallback to a default image
                    brand: "",
                    review: 0,
                    offer_price: sell_price,
                    price: sell_price,
                    campaingn_product: false,
                    cam_product_available: null,
                    cam_product_sale: null,
                    product_type: null
                };
            }));

            setTransformedProducts(transformedArray);
        }
    }


    useEffect(() => {
        const data = props.top_categories?.data;
        const initialCategory = tabs[selectedTab];
        const initialCategoryProducts = getProductsByCategory(data, initialCategory);
        transformedProductData(initialCategoryProducts)
    }, [selectedTab, tabs, props.top_categories]);

    useEffect(() => {
        // Fetch main category tree when the component mounts
        setTimeout(() => {
            props.getHomeTopSellerCategories();
        }, 3000);
    }, []);

    useEffect(() => {
        const data = props.top_categories?.data;
        const uniqueCategoryNames = [...new Set(data.map(item => item.website_category.title))];
        setTabs(uniqueCategoryNames)
    }, [props.top_categories]);


    function getProductsByCategory(data, categoryName) {
        const category = data.find((item) => item.website_category.title === categoryName);
        return category ? category : [];
    }

    // const {products} = datas;
    // const brands = [];
    // products.forEach((product) => {
    //     brands.push(product.brand);
    // });

    return (
        <div data-aos="fade-up" className={`w-full ${className || ""}`}>

            <div className="container-x mx-auto">
                <div>
                    <h2 className="sm:text-3xl text-xl mb-4 font-600 text-qblacktext">
                        {props.sectionTitle}
                    </h2>
                </div>

                {/* Material-UI Tabs */}
                <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" className='tabsMan'>
                    {tabs.map((category, index) => (
                        <Tab key={index} label={category}  />
                    ))}
                </Tabs>

                {/* Content for each tab */}
                {tabs.map((category, index) => (
                    <div key={index} style={{ display: selectedTab === index ? 'block' : 'none' }}>
                        <Typography component="div">
                            <SectionStyleThree
                                products={transformedProducts}
                                // sectionTitle="New Arrivals"
                                seeMoreUrl="/all-products"
                                className="new-products mb-[60px]"
                            />
                        </Typography>
                    </div>
                ))}


            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    top_categories: state.home.topCategories,
});

const mapDispatchToProps = dispatch => ({
    getHomeTopSellerCategories: () => dispatch(getHomeTopSellerCategories()),
});


export default connect(mapStateToProps, mapDispatchToProps)(SellerCategoriesProducts);
