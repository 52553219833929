import InputQuantityCom from "../Helpers/InputQuantityCom";
import { connect } from "react-redux";
import { useShoppingCart } from "use-shopping-cart";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ProductsTable = (props) => {
  const { className, reportHandler, productDetail } = props;
  const { cartDetails, clearCart, removeItem, cartCount, totalPrice } =
    useShoppingCart();
  const handleRemoveItem = (productId) => {
    // Remove the item from the cart
    removeItem(productId);
    toast.success(`Product remove successfully!`, {
      position: "top-right",
      autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            {/* table heading */}
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                product
              </td>
              <td className="py-4 whitespace-nowrap text-center for_responsive">
                price
              </td>
              <td className="py-4 whitespace-nowrap  text-center for_responsive">
                quantity
              </td>
              <td className="py-4 whitespace-nowrap  text-center for_responsive">
                total
              </td>
              <td className="py-4 whitespace-nowrap text-right w-[114px] for_responsive"></td>
            </tr>
            {/* table heading end */}
            {Object.values(cartDetails).map((cartDetail) => (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td className="pl-10  py-4  w-[380px]">
                  <div className="flex space-x-6 items-center custom_table_main">
                    <div className="custom_table">
                      <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                        <img
                          // src={`${process.env.PUBLIC_URL}/assets/images/product-img-1.jpg`}
                          src={`${cartDetail.baseImage}`}
                          alt="product"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex space-x-1 items-center justify-center d-none display_block">
                        <span onClick={() => handleRemoveItem(cartDetail.id)}>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                              fill="#ff0000"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col">
                      <p className="font-medium text-[15px] text-qblack">
                        <Link to={"/" + cartDetail.url}>
                          {cartDetail.title}
                        </Link>
                      </p>
                    </div>
                  </div>
                </td>
                <td className="text-center py-4 px-2">
                  <div className="custom_table_bottom">
                    <div className="flex space-x-1 items-center justify-center">
                      <label className="custom_table_bottom_label d-none">
                        Price
                      </label>
                      <span className="text-[15px] font-normal">
                        ${cartDetail.price}
                      </span>
                    </div>
                    <div className="flex justify-center items-center d-none">
                      <label className="custom_table_bottom_label d-none">
                        Quantity
                      </label>
                      <InputQuantityCom
                        productId={cartDetail.id}
                        originalQuantity={cartDetail.quantity}
                      />
                    </div>
                    <div className="flex space-x-1 items-center justify-center d-none">
                      <label className="custom_table_bottom_label d-none">
                        Sub Total
                      </label>
                      <span className="text-[15px] font-normal">
                        $
                        {parseFloat(
                          cartDetail.price * cartDetail.quantity
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </td>
                <td className=" py-4 for_responsive">
                  <div className="flex justify-center items-center">
                    <InputQuantityCom
                      productId={cartDetail.id}
                      originalQuantity={cartDetail.quantity}
                    />
                  </div>
                </td>
                <td className="text-right py-4 for_responsive">
                  <div className="flex space-x-1 items-center justify-center">
                    <span className="text-[15px] font-normal">
                      $
                      {parseFloat(
                        cartDetail.price * cartDetail.quantity
                      ).toFixed(2)}
                    </span>
                  </div>
                </td>
                <td className="text-right py-4 for_responsive">
                  <div className="flex space-x-1 items-center justify-center">
                    <span onClick={() => handleRemoveItem(cartDetail.id)}>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                          fill="#ff0000"
                        />
                      </svg>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTable);
