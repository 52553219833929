import {commonConstants, customerConstants, orderConstants} from "../../constants";

const initialState = {
    faqRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    returnPoliciesRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    shippingMethodRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    blogsRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    orderRes :{},

    orderUpdateRes :{
        data:{}
    },

    quoteRes :{},

    countryRes:{
        success:false,
        data: []
    },

    statesRes:{
        success:false,
        data: []
    },

    cityRes:{
        success:false,
        data: []
    },

    fedexRes:{
        data: []
    },


    paymentMethodRes: {
        data: [],
    },

    cardRes :{
        success:'',
        data:{},
    },

    blogDetailRes :{
        data:{}
    },
    pageRes :{
        data:{}
    },
    webPageRes :{
        data:{}
    },
    orderPriceCheckRes:{
        success:false,
        data:[],
        message:''
    },

    // Add other initial state properties as needed
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case commonConstants.FAQ:
            return {
                ...state,
                faqRes: action.payload.data,
                loading: false,
            };
        case commonConstants.FAQ_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.FEDEX:
            return {
                ...state,
                fedexRes: action.payload.data,
                loading: false,
            };
        case commonConstants.FEDEX_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
            //fedex reset
        case commonConstants.RESET_FEDEX:
            return {
                ...state,
                fedexRes: { data: [] },
                loading: false,
            };
        case commonConstants.PAYMENT_METHOD:
            return {
                ...state,
                paymentMethodRes: action.payload.data,
                loading: false,
            };
        case commonConstants.PAYMENT_METHOD_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.COUNTRIES:
            return {
                ...state,
                countryRes: action.payload.data,
                loading: false,
            };
        case commonConstants.COUNTRIES_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.STATES:
            return {
                ...state,
                statesRes: action.payload.data,
                loading: false,
            };
        case commonConstants.STATES_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.CITY:
            return {
                ...state,
                cityRes: action.payload.data,
                loading: false,
            };
        case commonConstants.CITY_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.RETUEN_POLICIES:
            return {
                ...state,
                returnPoliciesRes: action.payload.data,
                loading: false,
            };
        case commonConstants.RETUEN_POLICIES_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case orderConstants.ORDER:
            return {
                ...state,
                orderRes: action.payload,
                loading: false,
            };
        case orderConstants.ORDER_ERROR:
            return {
                ...state,
                orderRes: action.payload,
                loading: false,
            };

        case orderConstants.ORDER_UPDATE:
            return {
                ...state,
                orderUpdateRes: action.payload,
                loading: false,
            };
        case orderConstants.ORDER_UPDATE_ERROR:
            return {
                ...state,
                orderUpdateRes: action.payload,
                loading: false,
            };

        case orderConstants.ORDER_PRICE_CHECK:
            return {
                ...state,
                orderPriceCheckRes: action.payload,
                loading: false,
            }
        case orderConstants.ORDER_PRICE_CHECK_ERROR:
            return {
                ...state,
                orderPriceCheckRes: action.payload,
                loading: false,
            }
        case commonConstants.QUOTE:
            return {
                ...state,
                quoteRes: action.payload,
                loading: false,
            };
        case commonConstants.QUOTE_ERROR:
            return {
                ...state,
                quoteRes: action.payload,
                loading: false,
            };
        case commonConstants.CARD:
            return {
                ...state,
                cardRes: action.payload,
                loading: false,
            };
        case commonConstants.CARD_ERROR:
            return {
                ...state,
                cardRes: action.payload,
                loading: false,
            };
        case commonConstants.RESET_PAYMENT_CARD:
            return {
                ...state,
                cardRes: {},
            };
        case orderConstants.RESET_ORDER:
            return {
                ...state,
                orderRes: {},
            };
        case commonConstants.SHIPPING_METHOD:
            return {
                ...state,
                shippingMethodRes: action.payload.data,
                loading: false,
            };
        case commonConstants.SHIPPING_METHOD_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case commonConstants.BLOGS:
            return {
                ...state,
                blogsRes: action.payload.data,
            }
        case commonConstants.BLOGS_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case commonConstants.PAGE:
            return {
                ...state,
                pageRes: action.payload,
            }
        case commonConstants.PAGE_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case commonConstants.WEBSITE_PAGE:
            return {
                ...state,
                webPageRes: action.payload,
            }
        case commonConstants.WEBSITE_PAGE_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }
        case commonConstants.BLOG_DETAIL:
            return {
                ...state,
                blogDetailRes: action.payload.data,
            }
        case commonConstants.BLOG_DETAIL_ERROR:
            return {
                ...state,
                // Handle error state as needed
            }

        default:
            return state;
    }
};

export default commonReducer;
