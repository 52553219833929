import Cart from "../../../Cart";
import Compair from "../../../Helpers/icons/Compair";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import SearchBox from "../../../Helpers/SearchBox";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useShoppingCart } from "use-shopping-cart";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

const Middlebar = (props) => {
  const { className, type, isSticky } = props;
  const { cartDetails, clearCart, removeItem, cartCount, totalPrice } =
    useShoppingCart();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  const [webSiteData, setWebSiteData] = useState(null);
  let authUserData = localStorage.getItem('authUserData');

  useEffect(() => {
    // Get auth data from local storage and parse it
    authUserData = authUserData ? JSON.parse(authUserData) : null;

    // Set customer data
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    setUserData(user_data?.user);

    // Check if authUserData is available and process it
    if (authUserData && authUserData.user && authUserData.user.websites) {
      const currentWebsite = authUserData.user.websites.find(website =>
        website.url === process.env.REACT_APP_DOMAIN_NAME
      );
      if (currentWebsite) {
        setWebSiteData(currentWebsite);
      }
    }
  }, [authUserData]);

  const handleLogout = () => {
    localStorage.removeItem('user_data');
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  const headerClass = isSticky
    ? "mx-auto h-[70px] pl-5 pr-5 pt-2 pb-2"
    : "mx-auto h-[80px] pl-5 pr-5 pt-5 pb-5";

  return (
    <div className={`w-full h-full bg-white ${className}`}>
      <div className={headerClass}>
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full ">
            <div className="header_left">
            <div className="mb-3">
              {isSticky ? (
                <a href="/">
                  <img
                    width="330"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                    alt="sticky-logo"
                  // style={{ marginLeft: '100px' }}
                  />
                </a>
              ) : type === 3 ? (
                <a href="/">
                  <img
                    width="400"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                    alt="logo"
                  />
                </a>
              ) : type === 4 ? (
                <a href="/">
                  <img
                    width="400"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                  />
                </a>
              ) : (
                <a href="/">
                  <img
                    width="400"
                    height="36"
                    loading={"lazy"}
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                    alt="logo"
                  />
                </a>
              )}
            </div>
            <div className="w-[800px] h-[44px] headerSearch">
              <SearchBox type={type} className="search-com" />
            </div>
            </div>
            <div className="flex space-x-6 items-center">
              <div className="cont-num">
                <a
                  href="https://www.shopperapproved.com/reviews/etechbuy.com"
                  target="_blank"
                  className="shopperlink new-sa-seals placement-default"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Shopper-Approved.png`}
                    style={{ borderRadius: "4px" }}
                    alt="Customer Reviews"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      var d = new Date();
                      alert(
                        "Copying Prohibited by Law - This image and all included logos are copyrighted by Shopper Approved \u00A9 " +
                        d.getFullYear() +
                        "."
                      );
                      return false;
                    }}
                  />
                </a>
                <script type="text/javascript">
                  {`
                        (function () {
                          var js = window.document.createElement("script");
                          js.text = 'function openshopperapproved(o){ var e="Microsoft Internet Explorer"!=navigator.appName?"yes":"no",n=screen.availHeight-90,r=940;return window.innerWidth<1400&&(r=620),window.open(this.href,"shopperapproved","location="+e+",scrollbars=yes,width="+r+",height="+n+",menubar=no,toolbar=no"),o.stopPropagation&&o.stopPropagation(),!1}!function(){for(var o=document.getElementsByClassName("shopperlink"),e=0,n=o.length;e<n;e++)o[e].onclick=openshopperapproved}();';
                          js.type = "text/javascript";
                          document.getElementsByTagName("head")[0].appendChild(js);
                          var link = document.createElement('link');
                          link.rel = 'stylesheet';
                          link.type = 'text/css';
                          link.href = "//www.shopperapproved.com/seal/default.css";
                          document.getElementsByTagName('head')[0].appendChild(link);
                        })();
                      `}
                </script>
              </div>

              <div className="cont-num">
                {webSiteData?.config?.phone_number ? (
                  <Link to={`tel:${webSiteData.config.phone_number}`}>
                    {webSiteData.config.phone_number}
                  </Link>
                ) : (
                  <p>Loading phone number...</p>
                )}
              </div>
              <div className="cart-wrapper group relative py-4 ">
                <div className="cart relative cursor-pointer">
                  <a href="/cart" aria-label="View Cart">
                    <span>
                      <ThinBag />
                    </span>
                  </a>
                  <span
                    className={`w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? "bg-qh3-blue text-white" : "bg-qyellow"
                      }`}
                  >
                    {cartCount.toFixed(0)}
                  </span>
                </div>
                <Cart
                  totalPrice={totalPrice}
                  cartDetails={cartDetails}
                  type={type}
                  className="absolute -right-[5px] top-12 z-50 hidden group-hover:block"
                />
              </div>
              <div className="pr-5">
                <Link to="/login">
                </Link>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" aria-label="Login page button" >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <ThinPeople size={19} />
                      {
                        (userData?.firstname
                          ? userData.firstname.substring(0, 10)
                          : "")}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {userData?.firstname ? (
                      <>
                        <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item as={Link} aria-label="Login page button" to="/login">Login</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Middlebar);
