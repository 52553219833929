import {getMaincategoryTreeview} from "../actions/commonAction";

export const homeConstants = {
    TEST: 'TEST',
    TEST_ERROR: 'TEST_ERROR',

    FEATURE_CATEGORIES: 'FEATURE_CATEGORIES',
    FEATURE_CATEGORIES_ERROR: 'FEATURE_CATEGORIES_ERROR',

    MAIN_CATEGORY_TREE: 'MAIN_CATEGORY_TREE',
    MAIN_CATEGORY_TREE_ERROR: 'MAIN_CATEGORY_TREE_ERROR',

    NAVBAR_HEADER       : 'NAVBAR_HEADER',
    NAVBAR_HEADER_ERROR : 'NAVBAR_HEADER_ERROR',

    NAVBAR_HEADER_DETAIL       : 'NAVBAR_HEADER_DETAIL',
    NAVBAR_HEADER_DETAIL_ERROR : 'NAVBAR_HEADER_DETAIL_ERROR',

    MAIN_CATEGORY_DETAIL_HEADER       : 'MAIN_CATEGORY_DETAIL_HEADER',
    MAIN_CATEGORY_DETAIL_HEADER_ERROR : 'MAIN_CATEGORY_DETAIL_HEADER_ERROR',

    MAIN_MANUFACTURES_DETAIL_HEADER : 'MAIN_MANUFACTURES_DETAIL_HEADER',
    MAIN_MANUFACTURES_DETAIL_HEADER_ERROR : 'MAIN_MANUFACTURES_DETAIL_HEADER_ERROR',

    MANUFACTURE_LISTING     : 'MANUFACTURE_LISTING',
    MANUFACTURE_LISTING_ERROR : 'MANUFACTURE_LISTING_ERROR',


    BANNER       : 'BANNER',
    BANNER_ERROR : 'BANNER_ERROR',


    TOP_MANUFACTURE       : 'TOP_MANUFACTURE',
    TOP_MANUFACTURE_ERROR : 'TOP_MANUFACTURE_ERROR',


    TOP_CATEGORY       : 'TOP_CATEGORY',
    TOP_CATEGORY_ERROR : 'TOP_CATEGORY_ERROR',

    BRANDS       : 'BRANDS',
    BRANDS_ERROR : 'BRANDS_ERROR',

    ZONE                : 'ZONE',
    ZONE_ERROR          : 'ZONE_ERROR',
};

export const commonConstants = {
    BLOGS      : 'BLOGS',
    BLOGS_ERROR: 'BLOGS_ERROR',

    BLOG_DETAIL      : 'BLOG_DETAIL',
    BLOG_DETAIL_ERROR: 'BLOG_DETAIL_ERROR',

    FAQ: 'FAQ',
    FAQ_ERROR: 'FAQ_ERROR',

    PAGE: 'PAGE',
    PAGE_ERROR: 'PAGE_ERROR',

    WEBSITE_PAGE: 'WEBSITE_PAGE',
    WEBSITE_PAGE_ERROR: 'WEBSITE_PAGE_ERROR',

    RETUEN_POLICIES: 'RETUEN_POLICIES',
    RETUEN_POLICIES_ERROR: 'RETUEN_POLICIES_ERROR',

    QUOTE: 'QUOTE',
    QUOTE_ERROR: 'QUOTE_ERROR',

    COUNTRIES: 'COUNTRIES',
    COUNTRIES_ERROR: 'COUNTRIES_ERROR',

    STATES: 'STATES',
    STATES_ERROR: 'STATES_ERROR',

    CITY: 'CITY',
    CITY_ERROR: 'CITY_ERROR',


    FEDEX: 'FEDEX',
    FEDEX_ERROR: 'FEDEX_ERROR',
    RESET_FEDEX:'RESET_FEDEX',

    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAYMENT_METHOD_ERROR: 'PAYMENT_METHOD_ERROR',

    SHIPPING_METHOD: 'SHIPPING_METHOD',
    SHIPPING_METHOD_ERROR: 'SHIPPING_METHOD_ERROR',

    CARD: 'CARD',
    CARD_ERROR: 'CARD_ERROR',
    RESET_PAYMENT_CARD : 'RESET_PAYMENT_CARD'
}

export const productConstants = {
    PRODUCT: 'PRODUCT',
    PRODUCT_ERROR: 'PRODUCT_ERROR',

    PRODUCT_SEARCH: 'PRODUCT_SEARCH',
    PRODUCT_SEARCH_ERROR: 'PRODUCT_SEARCH_ERROR',

    PRODUCT_DETAIL: 'PRODUCT_DETAIL',
    PRODUCT_DETAIL_ERROR: 'PRODUCT_DETAIL_ERROR',
    PRODUCT_DETAIL_RESET: 'PRODUCT_DETAIL_RESET',

    PRODUCT_RELATED: 'PRODUCT_RELATED',
    PRODUCT_RELATED_ERROR: 'PRODUCT_RELATED_ERROR',

    PRODUCT_FILTER: 'PRODUCT_FILTER',
    PRODUCT_FILTER_ERROR: 'PRODUCT_FILTER_ERROR',
}

export const orderConstants = {
    ORDER: 'ORDER',
    ORDER_ERROR: 'ORDER_ERROR',
    RESET_ORDER : 'RESET_ORDER',

    ORDER_UPDATE: 'ORDER_UPDATE',
    ORDER_UPDATE_ERROR: 'ORDER_UPDATE_ERROR',

    ORDER_PRICE_CHECK : 'ORDER_PRICE_CHECK',
    ORDER_PRICE_CHECK_ERROR : 'ORDER_PRICE_CHECK_ERROR',

}

export const customerConstants = {
    SIGNUP : 'SIGNUP',
    SIGNUP_ERROR :"SIGNUP_ERROR",

    PROFILE_UPDATE : 'PROFILE_UPDATE',
    PROFILE_UPDATE_ERROR :"PROFILE_UPDATE_ERROR",

    LOGIN : 'LOGIN',
    LOGIN_ERROR :"LOGIN_ERROR",
    RESET_LOGIN :"RESET_LOGIN",


    PASSWORD_CHANGE : 'PASSWORD_CHANGE',
    PASSWORD_CHANGE_ERROR :"PASSWORD_CHANGE_ERROR",

    CUSTOMER_ORDER : 'CUSTOMER_ORDER',
    CUSTOMER_ORDER_ERROR :"CUSTOMER_ORDER_ERROR",

    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

    //use for create review
    POST_REVIEW: 'POST_REVIEW',
    POST_REVIEW_ERROR: 'POST_REVIEW_ERROR',
    POST_REVIEW_RESET: 'POST_REVIEW_RESET',

    //use for update review
    UPDATE_REVIEW: 'UPDATE_REVIEW',
    UPDATE_REVIEW_ERROR: 'UPDATE_REVIEW_ERROR',
    UPDATE_REVIEW_RESET: 'UPDATE_REVIEW_RESET',

    //use for listing
    CUSTOMER_REVIEW : 'CUSTOMER_REVIEW',
    CUSTOMER_REVIEW_ERROR :"CUSTOMER_REVIEW_ERROR",

    GET_REVIEW_BY_CATEGORY : 'GET_REVIEW_BY_CATEGORY',
    GET_REVIEW_BY_CATEGORY_ERROR :"GET_REVIEW_BY_CATEGORY_ERROR",


}