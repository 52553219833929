import React, { useState } from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper, Typography,} from "@mui/material";
import { toast } from "react-toastify";
import { useShoppingCart } from "use-shopping-cart";
import { FaWrench } from "react-icons/fa6";
import {Link, useLocation, useNavigate} from "react-router-dom";

const AccessoryProductsTable = ({ groupedProducts = {} }) => {
  const { addItem } = useShoppingCart();
  const [searchTerm, setSearchTerm] = useState({});
  const [page, setPage] = useState({});
  const rowsPerPage = 5;
  const currentDate = new Date();
  const navigate = useNavigate();


  const handleSearchChange = (category, event) => {
    setSearchTerm((prev) => ({
      ...prev,
      [category]: event.target.value,
    }));
  };

  const handlePageChange = (category, event, newPage) => {
    setPage((prev) => ({
      ...prev,
      [category]: newPage,
    }));
  };

  const handleRowsPerPageChange = (category, event) => {
    // Handle rows per page if needed
  };

  const addItemHandler = (product) => {
    if (product.sell_price === "0.00" || product.sell_price <= 0) {
      navigate('/reqforquote');
    }else {
      let productData = {
        id: product.id,
        product_id: product.product_id,
        title: product.title,
        baseImage: product.image,
        url: product.url,
        redirect_url: product.redirect_url,
        short_description: product.short_description,
        long_description: product.long_description,
        cost_price: product.cost_price,
        sell_price: product.sell_price,
        special_price: product.special_price,
        price:
            product.special_price !== null
                ? product.special_price
                : product.sell_price,
        discount: product.discount,
        qty: product.qty,
        meta_title: product.meta_title,
        meta_description: product.meta_description,
        meta_keywords: product.meta_keywords,
        product: product.product,
        attributes: product.attributes,
      };

      addItem(productData);
      toast.success(`${product.title} added to the cart!`, {
        position: "top-right",
        autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      {Object.keys(groupedProducts).map((category) => {
        const products = groupedProducts[category] || [];
        const searchTermValue = searchTerm[category] || "";
        const currentPage = page[category] || 0;

        const filteredProducts = products.filter((product) =>
          product.title.toLowerCase().includes(searchTermValue.toLowerCase())
        );

        const paginatedProducts = filteredProducts.slice(
          currentPage * rowsPerPage,
          (currentPage + 1) * rowsPerPage
        );

        return (
          <div
            key={category}
            style={{ marginBottom: "20px" }}
            className="accessory_products_main"
          >
            <div className="search_bar_main">
              <Typography variant="h6" style={{ margin: "10px 0px 10px 0px" }}>
                {category}
              </Typography>
              <TextField
                label={`Search in ${category}`}
                variant="outlined"
                fullWidth
                value={searchTermValue}
                onChange={(event) => handleSearchChange(category, event)}
                className="search_text_field"
              />
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>MPN</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Add To Cart</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProducts.length > 0 ? (
                    paginatedProducts.map((product) => (
                      <TableRow key={product.id}>
                        <Link to={product.url}>
                          {product.title.length > 50 ? `${product.title.substring(0, 50)}...` : product.title}
                        </Link>
                        <TableCell className="mpn"><Link to={product.url} >{product.mpn}</Link></TableCell>
                        <TableCell>
                          <img
                            src={product.image}
                            alt={product.title}
                            style={{ width: 80, height: 80 }}
                          />
                        </TableCell>
                        <TableCell className="price">${product.price}</TableCell>
                        <TableCell>
                          {(() => {
                            const specialPriceTo = new Date(product.special_price_to);
                            const specialPriceFrom = new Date(product.special_price_from);

                            if (product?.special_price && product.special_price !== "0" && product.special_price !== 0 && specialPriceTo >= currentDate && specialPriceFrom <= currentDate) {
                              return (
                                  <>
                                    <span className="priceText font-500 text-qgray line-through mt-2">
                                      ${product.sell_price}
                                    </span>
                                    <span className="priceText font-500 text-qred mt-2">
                                      ${product.special_price}
                                    </span>
                                  </>
                              );
                            } else {
                              return (
                                  <>
                                    {product.sell_price !== "0.00" && product.data?.sell_price !== 0 && product.data?.sell_price !== "0" ? (
                                        <button type="button" className="add-to-cart-btn" onClick={() => addItemHandler(product)} aria-label="Add To Cart">
                                          <FaWrench />
                                          <div className="flex items-center space-x-3">
                                            <span>Add To List</span>
                                          </div>
                                        </button>
                                    ) : (
                                        <button type="button" className="add-to-cart-btn" onClick={() => addItemHandler(product)} aria-label="Add To Cart">
                                          <FaWrench />
                                          <div className="flex items-center space-x-3">
                                            <span>Call for price</span>
                                          </div>
                                        </button>
                                    )}
                                  </>
                              );
                            }
                          })()}
                        </TableCell>

                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>No products found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={filteredProducts.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={(event, newPage) =>
                handlePageChange(category, event, newPage)
              }
              onRowsPerPageChange={(event) =>
                handleRowsPerPageChange(category, event)
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default AccessoryProductsTable;
