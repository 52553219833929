import React from "react";

const PaginationComponent = ({ currentPage, totalPages, onPageChange, pageLimit }) => {
  const handlePageChange = (newPage) => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // If newPage exceeds the pageLimit, wrap around to page 1
    if (newPage > pageLimit) {
      onPageChange(1);
    } else {
      onPageChange(newPage);
    }
  };

  const renderPageButtons = () => {
    const visiblePages = 5;
    const halfVisiblePages = Math.floor(visiblePages / 2);

    const startPage = Math.max(currentPage - halfVisiblePages, 1);
    const endPage = Math.min(startPage + visiblePages - 1, Math.min(pageLimit, totalPages));

    return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
    ).map((page) => (
        <li
            key={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
        >
          <button
              className="page-link"
              disabled={currentPage === page} // Disable the current page button
              onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        </li>
    ));
  };

  return (
      <div className="PagiDiv mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(1)}
                  aria-label="First"
              >
                <span aria-hidden="true">First</span>
              </button>
            </li>

            <li className="page-item">
              <button
                  className="page-link"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>

            {renderPageButtons()}

            <li className="page-item">
              <button
                  className="page-link"
                  disabled={currentPage === pageLimit}
                  onClick={() => handlePageChange(currentPage + 1)}
                  aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>

            <li className="page-item">
              <button
                  className="page-link"
                  disabled={currentPage === pageLimit}
                  onClick={() => handlePageChange(pageLimit)}
                  aria-label="Last"
              >
                <span aria-hidden="true">Last</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
  );
};

export default PaginationComponent;
