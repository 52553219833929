import React, { useEffect, useState } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { connect } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { getHomeBrands } from "../../actions/homeAction";
import { getProductFilters, getProducts } from "../../actions/productAction";
import { useNavigate } from "react-router-dom";
import "./ConfiguratorPage.css";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const Configurator = (props) => {
  const [manufacturer, setManufacturer] = useState([]);
  const [selectManufacturer, setSelectManufacturer] = useState();
  const [category, setCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState();
  const [productLine, setProductLine] = useState([]);
  const [selectProductLine, setSelectProductLine] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch brands when component mounts
  useEffect(() => {
    props.getHomeBrands();
  }, []);

  useEffect(() => {
      setProducts([]); // Clear products list
      setProductLine([]); // Clear product line list
      setSelectProduct(null); // Clear selected product
      setSelectProductLine(null); // Clear selected product line

      if (selectCategory) {
          const data = {
              type: "configurator",
              categoryId: selectCategory?.value,
              manufactureId: selectManufacturer?.value,
          };
          props.getProducts(data, 1, 10); // Fetch products
      }
  }, [selectManufacturer]);

  // Fetch product filters when component mounts
  useEffect(() => {
    props.getProductFilters("category_id=" + 157);
  }, []);

  // Set categories and product lines when product filters change
  useEffect(() => {
    if (props.product_filter_res?.categories) {
      const categories = props.product_filter_res.categories.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      setCategory(categories);


        const defaultCategory = categories.find((brand) => brand.label === "Server Systems");
        if (defaultCategory) {
            setSelectCategory(defaultCategory); // as default
        }

    }
  }, [props.product_filter_res]);

  // Fetch products when product line, category, or manufacturer changes
  useEffect(() => {
    setProducts([]); // Clear products list
      if (props.product_res?.data) {
          let newProducts = []; // Create an array to store matching products
          // Iterate through the product data
          props.product_res?.data.forEach((value) => {
              value.attributes.forEach((val) => {
                  if (val.name === "Product Line") {
                      val.values.forEach((data) => {
                          if (data === selectProductLine?.label) {
                              // Push matching products to newProducts array
                              newProducts.push({ label: value.title, value: value.url });
                          }
                      });
                  }
              });
          });

          // Update state with all the matching products at once
          setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      }

  }, [selectProductLine]);

  useEffect(() => {
    if (selectCategory) {
        const data = {
            type: "configurator",
            categoryId: selectCategory?.value,
            manufactureId: selectManufacturer?.value,
        };
        props.getProducts(data, 1, 10); // Fetch products
    }
  }, [selectCategory]);


  // Update products state when product response changes
  useEffect(() => {
    if (props.product_res?.data) {
      // const products = props.product_res.data.map((data) => ({
      //   label: data.title,
      //   value: data.url,
      // }));
      // setProducts(products);
        if (props.product_res?.data) {
            const uniqueProductLines = new Set(); // To ensure unique values
            let productLineOptions = [];

            props.product_res?.data.forEach((value) => {
                value.attributes.forEach((val) => {
                    if (val.name === "Product Line") {
                        val.values.forEach((data) => {
                            if (!uniqueProductLines.has(data)) {
                                uniqueProductLines.add(data); // Add to set to ensure uniqueness
                                productLineOptions.push({
                                    label: data,
                                    value: val.id,
                                });
                            }
                        });
                    }
                });
            });
            setProductLine(productLineOptions); // Set all unique options
        }

    }
  }, [props.product_res]);

  // Update manufacturers when home brands change
    useEffect(() => {
        if (props.home_brands?.data) {
            const brands = props.home_brands.data.map((data) => ({
                label: data.title,
                value: data.id,
            }));

            setManufacturer(brands);

            // Find HP in the brands and set it as the default selected manufacturer
            const hpBrand = brands.find((brand) => brand.label === "HP");
            if (hpBrand) {
                setSelectManufacturer(hpBrand); // Set HP as default
            }
        }
    }, [props.home_brands]);

  // Go button click handler
  const handleGo = () => {
      if(selectProduct?.value !== undefined && selectProduct?.value !== null){
          navigate("/"+selectProduct.value)
      }
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="about-page-wrapper w-full">
        <div className="title-area w-full">
          <PageTitle
            title="Configurator"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Configurator", path: "/configurator" },
            ]}
          />
        </div>
        <div className="aboutus-wrapper w-full configurator_page_wrapper">
          <div className="container-x mx-auto configuratorContainer">
            <div className="top_head">
              <h1>Customize and Optimize Your Ideal System</h1>
              <h4>
                Achieve the Perfect Setup In <span style={{ color: '#f5ad1d' }}>4 Simple Steps !</span>
              </h4>
            </div>
            <div className="bottom_head">
              <div className="bottom_head_fields Manufacture">
                <label className="label_main">Select Manufacture</label>
                  {manufacturer.length >0 && (
                      <Autocomplete
                          options={manufacturer}
                          getOptionLabel={(option) => option.label}
                          value={selectManufacturer} // Default selected value (should match one in the options)
                          onChange={(event, newValue) => setSelectManufacturer(newValue)} // Update selected value
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <>
                                              {params.InputProps.endAdornment}
                                              <ArrowDropDown />
                                          </>
                                      ),
                                  }}
                              />
                          )}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          freeSolo
                      />
                  )}
              </div>
              <div className="bottom_head_fields Category">
                <label className="label_main">Select Category</label>
                  {category.length > 0 && (
                      <Autocomplete
                          options={category}
                          getOptionLabel={(option) => option.label}
                          value={selectCategory}
                          onChange={(event, newValue) => setSelectCategory(newValue)}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <>
                                              {params.InputProps.endAdornment}
                                              <ArrowDropDown />
                                          </>
                                      ),
                                  }}
                              />
                          )}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                          freeSolo
                      />
                  )}
              </div>
              <div className="bottom_head_fields ProductLine">
                <label className="label_main">Select Product Line</label>
                <Autocomplete
                    options={productLine}
                    getOptionLabel={(option) => option.label}
                    value={selectProductLine}
                    onChange={(event, newValue) => setSelectProductLine(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                  <>
                                    {params.InputProps.endAdornment}
                                    <ArrowDropDown />
                                  </>
                              ),
                            }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    freeSolo
                />
              </div>
              <div className="bottom_head_fields ProductsSelect">
                <label className="label_main">Select Products</label>
                <Autocomplete
                    options={products}
                    getOptionLabel={(option) => option.label}
                    value={selectProduct}
                    onChange={(event, newValue) => setSelectProduct(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                  <>
                                    {params.InputProps.endAdornment}
                                    <ArrowDropDown />
                                  </>
                              ),
                            }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.value === value?.value}
                    freeSolo
                />
              </div>
              <div className="bottom_head_fields button">
                {loading ? (
                  <p>Loading products...</p>
                ) : (
                  <button
                    className="w-[93px] h-full text-sm font-600 btnSearchs search-btn"
                    onClick={handleGo}
                    type="button"
                  >
                    Go
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  home_brands: state.home.brandsRes,
  product_filter_res: state.product.productFilterRes,
  product_res: state.product.productRes,
});

const mapDispatchToProps = (dispatch) => ({
  getHomeBrands: () => dispatch(getHomeBrands(99999,true)),
  getProductFilters: (filter) => dispatch(getProductFilters(filter)),
  getProducts: (filters, page, per_page) =>
    dispatch(getProducts(filters, page, per_page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);
